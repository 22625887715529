import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { emailRegisterFinish, emailRegisterStart } from 'api';
import { AppRoutes } from 'config/routes';
import { EmailStartRegisterRequest } from 'models/api/auth/email-register-auth-request';
import { EmailStartAuthRequest } from 'models/api/auth/email-start-auth-request';
import { useCallback, useState } from 'react';
import { useConfirmStaffInviteMutation } from 'store/api/staff';
import { setStaffInviteId } from 'store/reducers/invite.conteragent';
import { useHandleMutation } from 'utils/handleMutation';
import { uploadClient as appolloClient } from 'api/apollo-upload-client';
import { gql } from '@apollo/client';

interface InviteAuthProps {
  password: string;
}

export default function useEmailStaffInvite() {
  const staffInviteToken = new URLSearchParams(window.location?.search || '').get('token');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: startRegister } = useMutation((data: EmailStartAuthRequest) =>
    emailRegisterStart(data),
  );

  const { mutateAsync: finishRegister } = useMutation((data: EmailStartRegisterRequest) =>
    emailRegisterFinish(data),
  );

  const [confirmInvite, confirmInviteMutation] = useConfirmStaffInviteMutation();

  const onSuccess = useCallback(() => {
    dispatch(setStaffInviteId(''));
  }, []);

  useHandleMutation({
    ...confirmInviteMutation,
    onSuccess: onSuccess,
  });

  const setPassword = async ({ password }: InviteAuthProps) => {
    const { Auth } = AppRoutes;

    try {
      setLoading(true);

      const { token } = await startRegister({
        password,
        invite_id: staffInviteToken ?? undefined,
      });

      try {
        if (staffInviteToken) {
          dispatch(setStaffInviteId(''));
        }

        await Promise.resolve(() => {
          setTimeout(() => {
            //
          }, 1000);
        });

        const { access_token } = await finishRegister({ password, data: {}, token });

        if (staffInviteToken) {
          await appolloClient.mutate({
            mutation: gql`
              mutation CoreEmployeeInvitations($invitationId: ID!) {
                coreEmployeeInvitations {
                  acceptInvitation(input: { invitationId: $invitationId, isAccepted: true })
                }
              }
            `,
            variables: { invitationId: staffInviteToken },
            context: {
              headers: {
                authorization: `Bearer ${access_token}`,
              },
            },
          });

          confirmInvite({ id: staffInviteToken });
        }

        return access_token;
      } catch (error: any) {
        if (error?.message) {
          navigate(`${Auth.Home}${Auth.Register}`);
          message.error(error?.message as string);
        } else {
          throw t('Something went wrong...');
        }
      }

      !state ? navigate('/') : navigate(state.from);
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      setLoading(false);
    }
  };

  return { setPassword, isLoading };
}
