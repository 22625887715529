import cx from 'classnames';
import { Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { OrganizationFormValues, initialValues, validationSchema } from './config';

import {
  BankRequisitesForm,
  ContactInformationForm,
  KppForm,
  LegalAddressForm,
  OKPOForm,
  PostalAddressForm,
} from 'components/forms/organization';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useSetPaymentOptionMutation } from 'store/api/payments';
import { DefaultPaymentOption, Organization } from 'store/api/types';
import { useHandleMutation } from 'utils/handleMutation';

import { useUpdateMyOrganizationMutation } from 'store/api/organizations';
import s from './aside-organization.module.scss';

export const AsideOrganization = ({
  isOpen,
  payment,
  handleClose,
  organization,
}: {
  isOpen: boolean;
  handleClose: () => void;
  organization: Organization | undefined;
  payment: DefaultPaymentOption | undefined;
}) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const [updatePaymentOption, updatePaymentOptionMutation] = useSetPaymentOptionMutation();
  const [updateMyOrganization, updateMyOrganizationMutation] =
    useUpdateMyOrganizationMutation();

  const onSuccessPaymentOptionsUpdate = useCallback(() => {
    alert.success(t('Payment option successfully updated'));
  }, [alert.success, t]);

  const onErrorPaymentOptionsUpdate = useCallback(() => {
    alert.error(t('Payment option update failed'));
  }, [alert.error, t]);

  const onSuccessOrganizationUpdate = useCallback(() => {
    alert.success(t('Organization requisites sucessfully updated'));
  }, [alert.success, t]);

  const onErrorOrganizationUpdate = useCallback(() => {
    alert.error(t('Organization update failed'));
  }, [alert.error, t]);

  useHandleMutation({
    ...updatePaymentOptionMutation,
    onError: onErrorPaymentOptionsUpdate,
    onSuccess: onSuccessPaymentOptionsUpdate,
  });

  useHandleMutation({
    ...updateMyOrganizationMutation,
    onError: onErrorOrganizationUpdate,
    onSuccess: onSuccessOrganizationUpdate,
  });

  const isKppRequired = organization?.attributes?.find((item) => item.name === 'kpp');
  const currentCountry = organization?.countryCode;

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const onSubmit = async (values: OrganizationFormValues) => {
    const { bic, bankId, bankDepartment, paymentAccount, correspondentAccount } = values;
    const { okpo, kpp, email, phone, country, address, postalCountry, postalAddress } = values;

    const bankParams = {
      ...(okpo?.length ? { okpo } : {}),
      bic: bic ?? '',
      isDefault: true,
      currency: 'RUB',
      docId: '000000000',
      bankId: bankId ?? '',
      bankName: bankId ?? '',
      name: bankDepartment ?? '',
      accountNumber: paymentAccount ?? '',
      corrAccountNumber: correspondentAccount ?? '',
    };

    const attributes = [
      okpo,
      kpp,
      email,
      phone,
      country,
      address,
      postalCountry,
      postalAddress,
    ].filter((item) => item !== undefined);
    const attributesKeys = [
      'okpo',
      'kpp',
      'email',
      'phone',
      'country',
      'address',
      'postal_country',
      'postal_address',
    ];

    const attributesValues = Object.values(attributes);
    const organizationAttributes = attributesKeys.map((key, index) => ({
      name: key,
      value: attributesValues[index] ?? '',
    }));

    const organizationInput = {
      countryCode: country?.toLowerCase() ?? '',
      attributes: organizationAttributes,
    };

    await updatePaymentOption({
      id: payment?.id ?? '',
      input: { ...bankParams },
    });

    await updateMyOrganization({
      input: organizationInput,
    });

    handleClose();
  };

  const scrollToFirstError = (errors: FormikErrors<OrganizationFormValues>) => {
    const allErrorKeys = Object.keys(errors);

    for (const errorKey of allErrorKeys) {
      const errorElement = document.querySelector(`[name="${errorKey}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
        break;
      }

      const selectElement = document.getElementById(`${errorKey}`);
      if (selectElement) {
        selectElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });

        break;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={() => validationSchema({ t, organization })}
      initialValues={initialValues({ organization, payment })}
    >
      {({ values, dirty, isSubmitting, submitForm, errors }) => {
        return (
          <div className={containerClasses}>
            <div className={wrapperClasses}>
              <div className={s['header']}>
                <span className={s['title']}>{t('Editing')}</span>

                <div
                  role="button"
                  tabIndex={0}
                  className={s['close']}
                  onClick={() => handleClose()}
                  onKeyDown={(e) => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </div>
              </div>

              <div className={s['main']}>
                <div className={s['scroll']}>
                  {currentCountry === 'kg' || (currentCountry === 'kgz' && <OKPOForm />)}
                  {isKppRequired && <KppForm />}
                  <LegalAddressForm />
                  <PostalAddressForm />
                  <ContactInformationForm country={currentCountry ?? ''} />
                  <BankRequisitesForm />
                </div>
              </div>

              <div className={s['footer']}>
                <Button
                  displayType="outlined-secondary"
                  className={classesButtonBack}
                  onClick={() => handleClose()}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={() => {
                    submitForm().then(() => scrollToFirstError(errors));
                  }}
                  className={classesButtonSave}
                  disabled={isSubmitting || !dirty}
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
