import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue, components as selectComponents } from 'react-select';

import { Select } from 'components/common/select/select';

import { SelectProps, SelectOption } from 'components/common/select/select-types';

import s from './select-tags.module.scss';

interface SelectTagsProps extends SelectProps {
  isColumn?: boolean;
  isMulti?: boolean;
  items: SingleValue<SelectOption> | MultiValue<SelectOption>;
  onClose?: (item: SelectOption) => void;
}

const CustomMultiValue = (props: any) => {
  const { data, selectProps } = props;
  const maxTagsToShow = 3;

  const selectedTags = selectProps.value || [];
  const selectedTagsCount = selectedTags.length;

  if (selectedTagsCount <= maxTagsToShow) {
    return (
      <selectComponents.MultiValue {...props}>
        <span>{data.label}</span>
      </selectComponents.MultiValue>
    );
  }

  if (selectedTags.indexOf(data) < maxTagsToShow) {
    return (
      <selectComponents.MultiValue {...props}>
        <span>{data.label}</span>
      </selectComponents.MultiValue>
    );
  }

  if (selectedTags.indexOf(data) < maxTagsToShow + 1) {
    return <span>...</span>;
  }

  return null;
};

export const SelectTags = ({
  items,
  label,
  onClose,
  onChange,
  components = {},
  isColumn = false,
  isMulti = true,
  ...props
}: SelectTagsProps): JSX.Element => {
  const { t } = useTranslation();

  const selectedTagCount = items?.length || 0;
  const isMultipleTagsSelected = items && items.length > 3;

  let additionalTagsCount = 0;

  if (isMultipleTagsSelected) {
    additionalTagsCount = selectedTagCount - 3;
  }

  return (
    <div className={s['container']}>
      <Select
        isMulti={isMulti}
        isSearchable
        value={items}
        defaultControl
        components={{
          MultiValue: CustomMultiValue,
          ...components,
        }}
        noOptionsMessage={(e) => (e.inputValue ? 'No options' : null)}
        isClearable={false}
        isCloseMenuOnSelect={false}
        label={label}
        onChange={(option, action) => {
          if (onChange) {
            onChange(option, action);
          }
        }}
        {...props}
      />

      {isMultipleTagsSelected && (
        <span className={s['tags-count']}>+{additionalTagsCount}</span>
      )}
    </div>
  );
};
