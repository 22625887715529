import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import {
  useCreateSupplyProductionDocumentMutation,
  useGetShipmentRegistryTemplateQuery,
  useGetCoreDocumentsNoCacheMutation,
  useSignDocumentMutation,
} from 'store/api/documents';
import { useHandleMutation } from 'utils/handleMutation';
import {
  generateModelFromScheme,
  generateValidationSchema,
  parseSteps,
} from 'utils/sheme-parser';

export const useNew = () => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const path = useLocation().pathname;
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [step, setStep] = useState<number>(1);

  const [contractId, setContractId] = useState<string | undefined>();

  const [fetchDocument] = useGetCoreDocumentsNoCacheMutation();

  const [signDocument, signDocumentMutation] = useSignDocumentMutation();

  const { data: shipmentRegistryDocumentScheme } = useGetShipmentRegistryTemplateQuery({
    documentId: documentId ?? '',
  });

  useCreateSupplyProductionDocumentMutation();

  const openDocumentSign = (documentId: string) => {
    navigate(
      Home +
        AppRoutes.Dashboard.ShipmentRegistry.Home +
        AppRoutes.Dashboard.ShipmentRegistry.Preview +
        `/${documentId}`,
    );
  };

  const dropDownOptions = useMemo(
    () =>
      [
        shipmentRegistryDocumentScheme && {
          value: 'ShipmentRegistry',
          label: 'Реестр отгрузок по договору цессии',
          scheme: shipmentRegistryDocumentScheme,
        },
      ]?.filter((item) => item),
    [shipmentRegistryDocumentScheme],
  );

  const [currentSelect, setCurrentSelect] = useState(dropDownOptions[0]);
  const isApproval = path.includes(AppRoutes.Dashboard.Contracts.ContractApproval);

  const handleSign = useCallback(
    async (id?: string, version?: number) => {
      const { data } = (await fetchDocument({ ids: [documentId ?? contractId ?? ''] })) as any;

      const [document] = data ?? [];

      await signDocument({
        signature: null,
        detachedSignature: null,
        documentId: id ?? document?.id ?? '',
        documentVersion: version ?? document?.version ?? 0,
      });
    },
    [fetchDocument, documentId, contractId, signDocument],
  );

  useEffect(() => {
    if (contractId?.length) {
      // handleSign();
    }
  }, [contractId]);

  const onSuccessCreate = useCallback(async (id: string) => {
    setContractId(id);
    handleClose();
  }, []);

  const handleClose = useCallback(() => {
    navigate(Home + AppRoutes.Dashboard.ShipmentRegistry.Home);
  }, [navigate]);

  const onSuccessSign = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onErrorSign = useCallback((err: any) => {
    console.error('Error sign', err);
  }, []);

  useHandleMutation({
    ...signDocumentMutation,
    onSuccess: onSuccessSign,
    onError: onErrorSign,
  });

  useEffect(() => {
    setCurrentSelect(dropDownOptions[0]);
  }, [dropDownOptions, shipmentRegistryDocumentScheme]);

  const { currentScheme, model, steps, stepsLabels } = useMemo(() => {
    return {
      currentScheme: currentSelect?.scheme,
      model: generateModelFromScheme(currentSelect?.scheme, language),
      ...parseSteps(currentSelect?.scheme),
    };
  }, [currentSelect]);

  const getValidationSchema = () => {
    if (isApproval) {
      return Yup.object().shape({});
    }
    return generateValidationSchema(currentScheme, step);
  };

  return {
    step,
    steps,
    model,
    setStep,
    handleSign,
    isApproval,
    stepsLabels,
    handleClose,
    currentSelect,
    currentScheme,
    dropDownOptions,
    setCurrentSelect,
    openDocumentSign,
    getValidationSchema,
    isLoading: signDocumentMutation.isLoading,
  };
};
