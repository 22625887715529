import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import cx from 'classnames';
import { CheckboxField } from 'components/fields/checkbox-field';
import { Button } from 'components/ui/Button';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import s from './filter.module.scss';

type FiltersValues = {
  columns: { [key: string]: boolean }; // Dynamic keys for each column with boolean values
};

type Column = {
  key: string;
  label: string;
};

export const AsideTableSettings = ({
  isOpen,
  onSubmitForm,
  selectedColumns,
  onClose,
  labelTranslateModified = '',
  onResetToDefaults,
  columns, // Pass the array of columns
}: {
  isOpen: boolean;
  labelTranslateModified?: string;
  selectedColumns?: string[];
  onSubmitForm?: (values: { [key: string]: boolean }) => void;
  onResetToDefaults?: () => void;
  onClose: () => void;
  columns: Column[]; // Array of columns passed from parent
}) => {
  const { t } = useTranslation();

  const containerClasses = cx(s['container'], { [s['open']]: isOpen });
  const wrapperClasses = cx(s['wrapper'], { [s['open']]: isOpen });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  // Dynamically generate initial values
  const initialValues: FiltersValues = {
    columns: columns.reduce((acc, column) => {
      acc[column.key] = !!selectedColumns?.find((item) => item === column.key); // Initially set all columns to true (enabled)
      return acc;
    }, {} as { [key: string]: boolean }),
  };

  const onSubmit = (values: FiltersValues) => {
    onSubmitForm?.(values?.columns); // Pass the selected columns back to the parent
    onClose?.(); // Close the settings panel
  };

  const handleReset = (resetForm: FormikHelpers<any>['resetForm']) => {
    onResetToDefaults?.();
    resetForm({ values: initialValues });
  };

  return (
    <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
      {({ values, submitForm, resetForm }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Table Settings')}</span>
              <div role="button" tabIndex={0} className={s['close']} onClick={onClose}>
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['wrapper-select']} style={{ gap: 16 }}>
                {/* Render a checkbox for each column dynamically */}
                {columns.map((column) => (
                  <CheckboxField
                    key={column.key}
                    name={`columns.${column.key}`} // Formik name
                    label={`${labelTranslateModified}${column.label}`} // Column label
                  />
                ))}
              </div>
            </div>

            <div className={s['footer']}>
              {onResetToDefaults && (
                <Button className={classesButtonBack} onClick={() => handleReset(resetForm)}>
                  {t('Reset to defaults')}
                </Button>
              )}

              <Button className={classesButtonBack} onClick={onClose}>
                {t('Back')}
              </Button>
              <Button className={classesButtonSave} onClick={submitForm}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
