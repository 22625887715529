import { ContentProfile } from './content/Content';
import { HeaderProfile } from './header/HeaderProfile';

import s from './OrganizationProfile.module.scss';

export function OrganizationProfile() {
  return (
    <div className={s['container']}>
      <HeaderProfile />
      <ContentProfile />
    </div>
  );
}
