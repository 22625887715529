import { gql } from 'graphql-request';

export const GET_ALL_CONTROL_CARDS = gql`
  query App5182dd71_Document(
    $statusIds: [String!]
    $input: String
    $agreementId: String
    $shippingDateBefore: String
    $shippingDateAfter: String
  ) {
    app5182dd71_ControlCard {
      getList(
        filters: {
          input: $input
          statusIds: $statusIds
          agreementId: $agreementId
          shippingDateBefore: $shippingDateBefore
          shippingDateAfter: $shippingDateAfter
        }
        pagination: { page: 1, limit: 1000 }
      ) {
        data {
          id
          version
          shipmentTask {
            id
            version
            operationalRequest {
              id
              agreementName
              number
              requestDate
              deliveryBasis
              supplyAddress
              shippingDate
              goodsType
              quantity
              statusId
              employeeOwner
              employeeReceiving
              comment
            }
            statusId
            supplierExecutor
            buyerExecutor
            createdAt
            updatedAt
          }
          statusId
          actualShipmentDate
          actualQuantity
          createdAt
          updatedAt
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
