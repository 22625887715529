import { StatusDocument } from 'components/status/status-document';
import { useTranslation } from 'react-i18next';
import { OperationalRequest } from 'store/api/types/documents';
import s from './table.module.scss';

import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { useGetDocumentStatusesQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import Checkbox from 'antd/es/checkbox/Checkbox';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';

const getColumnStyle = (column: string) => {
  switch (column) {
    case 'deliveryBasis':
    case 'quantity':
    case 'number':
      return { maxWidth: 30, justifyContent: 'center' };
    case 'agreementName':
      return { minWidth: 300, maxHeight: 80, textOverflow: 'ellipsis' };
    case 'statusId':
      return { minWidth: 135 };
    case 'supplyAddress':
      return { minWidth: 200, textOverflow: 'ellipsis' };
    default:
      return { minWidth: 100 };
  }
};

export const TableRow = ({
  selectedColumns,
  item,
  onSelectRow,
  selectedRows,
}: {
  onSelectRow: (value: string) => void;
  selectedRows: string[];
  item: OperationalRequest;
  selectedColumns: string[];
}) => {
  const navigate = useNavigate();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { id, statusId, orgOwnerId } = item;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgOwnerId;

  const { Home, OperationalRequests } = AppRoutes.Dashboard;

  const handleDetailContract = (id: string) => {
    if (statusId === statuses?.find((item) => item?.alias === 'rejected')?.id && isOwner) {
      navigate(Home + OperationalRequests.Home + OperationalRequests.NewRequest + `/${id}`);
      return;
    }
    if (statusId === statuses?.find((item) => item?.alias === 'approved')?.id && !isOwner) {
      navigate(
        Home + OperationalRequests.Home + OperationalRequests.RequestApproval + `/${id}`,
      );
      return;
    }
    navigate(Home + OperationalRequests.Home + `/${id}` + OperationalRequests.BasicInformation);
  };

  const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDetailContract(id);
    }
  };

  return (
    <div
      onKeyDown={(e) => handleKeyDown(id, e)}
      onClick={(e) => {
        e.stopPropagation();
        handleDetailContract(id);
      }}
      key={item.id}
      className={s['table-row']}
      role="button"
      tabIndex={0}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          style={{ height: 100, width: 24, paddingLeft: 8 }}
          checked={selectedRows.some((value) => value === item.id)}
          onChange={(e) => {
            e.stopPropagation();
            onSelectRow(item.id);
          }}
        />
      </div>

      {selectedColumns.map((column: string) => {
        return (
          <TableItem key={column} content={(item as any)[column]} data={item} column={column} />
        );
      })}
    </div>
  );
};

export const TableItem = ({
  data,
  content,
  column,
}: {
  data: OperationalRequest;
  content: JSX.Element;
  column: string;
}) => {
  const navigate = useNavigate();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { statusId, orgOwnerId } = data;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgOwnerId;

  if (column === 'statusId') {
    return (
      <div style={getColumnStyle(column)} className={s['table-column']}>
        <StatusDocument text={status?.alias} isOwner={isOwner} />
      </div>
    );
  }

  if (column?.toLocaleLowerCase()?.includes('date')) {
    return (
      <div className={s['table-column']}>
        <span className={s['column-text']}>
          {dayjs((content ?? '')?.toString()).format(DefaultDateFormat)}
        </span>
      </div>
    );
  }

  return (
    <div style={getColumnStyle(column)} className={s['table-column']}>
      <span className={s['column-text']}>{content}</span>
    </div>
  );
};

export const OperationalRequestsTable = ({
  data,
  selectedColumns,
  onSelectRow,
  selectedRows,
}: {
  selectedRows: string[];
  onSelectRow: (value: string) => void;
  selectedColumns: string[];
  data: OperationalRequest[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={s['table']}>
      <div className={s['table-header']}>
        {[...selectedColumns].map((item) => (
          <div style={getColumnStyle(item)} key={item} className={s['table-column']}>
            <span className={s['header-text']}>{t(item)}</span>
          </div>
        ))}
      </div>

      <div className={s['table-body']}>
        {data.map((item: OperationalRequest) => (
          <TableRow
            selectedRows={selectedRows}
            onSelectRow={onSelectRow}
            key={item.agreementName?.toString()}
            item={item}
            selectedColumns={selectedColumns}
          />
        ))}
      </div>
    </div>
  );
};
