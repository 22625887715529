import { gql } from 'graphql-request';

export const GET_ONE_CONTROL_CARD = gql`
  query App5182dd71_Document($documentId: String!) {
    app5182dd71_ControlCard {
      getOne(id: $documentId) {
        id
        version
        shipmentTask {
          id
          version
          operationalRequest {
            id
            agreementName
            number
            requestDate
            deliveryBasis
            supplyAddress
            goodsType
            quantity
            statusId
            employeeOwner
            employeeReceiving
            comment
          }
          statusId
          supplierExecutor
          buyerExecutor
          createdAt
          updatedAt
        }
        statusId
        actualShipmentDate
        actualQuantity
        createdAt
        updatedAt
      }
    }
  }
`;
