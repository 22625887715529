import cx from 'classnames';
import { HeaderPage } from 'components/header-page/header-page';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';

import { DocumentHeaderInfo } from 'components/document-header-info';
import { Loader } from 'components/loader/loader';
import { Formik, FormikValues } from 'formik';
import {
  useGetCoreDocumentsQuery,
  useSendToCorrectOperationalRequestMutation,
} from 'store/api/documents';
import {
  SchemeModificators,
  generateForm,
  generateInitialValues,
} from 'utils/sheme-parser/generate-form';

import axios from 'axios';
import { AppRoutes } from 'config/routes';
import { useCallback, useEffect } from 'react';
import { useHandleMutation } from 'utils/handleMutation';
import { getToken } from 'utils/token';
import { NewRequestPageTemplate } from './new-task-template';
import styles from './NewRequest.module.scss';
import { useNew } from './use-new-task';
import { downloadShipmentRegistryDocument } from 'api';

const FormResetter = ({
  resetForm,
  setStep,
  currentSelect,
}: {
  resetForm: () => void;
  setStep: (step: number) => void;
  currentSelect: { value: string; label: string };
}) => {
  useEffect(() => {
    resetForm();
    setStep(1);
  }, [currentSelect]);

  return null;
};

export const NewRequestPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const withHeader = true;
  const withFooter = true;

  const {
    step,
    steps,
    model,
    isLoading,
    setStep,
    handleClose,
    isApproval,
    currentSelect,
    dropDownOptions,
    openDocumentSign,
    getValidationSchema,
  } = useNew();

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [id ?? ''] },
    { skip: !id },
  );

  const [sendToCorrect, sendToCorrectMutation] = useSendToCorrectOperationalRequestMutation();

  const onSuccess = useCallback(() => {
    navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentTasks.Home);
  }, [navigate]);

  useHandleMutation({ ...sendToCorrectMutation, onSuccess });

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const donwloadFile = async (documentId: string) => {
    await downloadShipmentRegistryDocument(documentId);
  };

  const onSubmit = async (values: FormikValues) => {
    if (step < steps[steps?.length - 1]) {
      setStep(step + 1);
    } else {
      // if (isApproval && id && document) {
      //   handleSign(document.id, document.version);
      //   return;
      // }

      const modifiedValues = { ...values };

      if (modifiedValues?.file?.size) {
        const formData = new FormData();
        const params = new URLSearchParams();

        params.append('documentId', id ?? '');
        formData.append('file', modifiedValues?.file);

        await axios({
          url: `${
            process.env.REACT_APP_API_BASE_PATH
          }/app5182dd71/upload-file?${params?.toString()}`,
          data: formData,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${getToken()}`,
          },
        });

        window.location.reload();
        return;
      }

      openDocumentSign(document.id);
    }
  };

  const isSchemeExist = () => {
    return dropDownOptions?.reduce((acc, item) => {
      if (item.scheme) {
        return true;
      }
      return acc;
    }, false);
  };

  if (isFetching || !isSchemeExist()) {
    return <Loader size="medium" />;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      initialValues={generateInitialValues(model)}
    >
      {({ submitForm, resetForm, values }) => (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <FormResetter
              currentSelect={currentSelect}
              resetForm={resetForm}
              setStep={setStep}
            />
            {withHeader && !isApproval && (
              <HeaderPage title={t(id ? 'Реестр отгрузок' : 'Реестр отгрузок')} />
            )}
            {withHeader && isApproval && <DocumentHeaderInfo isLongTitle document={document} />}

            <div className={styles.contentBox}>
              <div className={styles.card}>
                <NewRequestPageTemplate>
                  {generateForm({
                    model,
                    step,
                    formValues: values,
                    modificators: isApproval ? [SchemeModificators.disabled] : ([] as any),
                  })}
                </NewRequestPageTemplate>
              </div>
            </div>

            {withFooter && (
              <div className={styles['footer']}>
                {step > 1 && (
                  <Button
                    className={cx(styles['button'], styles['button-back'])}
                    onClick={handlePrevStep}
                  >
                    {t('Back')}
                  </Button>
                )}

                <Button
                  displayType="outlined-tetriary"
                  className={cx(styles['button'])}
                  onClick={handleClose}
                >
                  {t('Cancel')}
                </Button>
                {typeof (values as any).file === 'string' && (
                  <Button
                    displayType="outlined-tetriary"
                    className={cx(styles['button'])}
                    onClick={() => donwloadFile(document.id)}
                  >
                    {t('Скачать файл')}
                  </Button>
                )}
                <Button className={cx(styles['button'])} onClick={submitForm}>
                  {isLoading || sendToCorrectMutation?.isLoading
                    ? t('Loading...')
                    : t(typeof (values as any).file === 'string' ? 'Далее' : 'Загрузить файл')}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
