import { gql } from 'graphql-request';

export const REVOKE_CONTROL_CARDS = gql`
  mutation App5182dd71_Document($documentIds: [String!]!) {
    app5182dd71_ControlCard {
      terminate(documentIds: $documentIds) {
        status
        message {
          ru
          en
        }
      }
    }
  }
`;
