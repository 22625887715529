import { Route, Routes } from 'react-router-dom';

import All from './all/All';
import { DetailRequest } from './details/Details';

import { AppRoutes } from 'config/routes';
import { NewRequestPage } from './new-task';

function ControlTasks() {
  const { NewRequest, RequestApproval } = AppRoutes.Dashboard.OperationalRequests;

  return (
    <Routes>
      <Route path="/" element={<All />} />
      <Route path="/:id/*" element={<DetailRequest />} />

      <Route path={NewRequest} element={<NewRequestPage />} />
      <Route path={RequestApproval + '/:id'} element={<NewRequestPage />} />

      <Route path={NewRequest + '/:id'} element={<NewRequestPage />} />
    </Routes>
  );
}

export default ControlTasks;
