import { Button } from 'components/ui/Button';
import { useTranslation } from 'react-i18next';
import s from './LoginPage.module.scss';

import useLogin from 'hooks/use-login';
import { CertificateSelector } from 'modules/certificates';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCurrentCertificate, selectIsSignLoading } from 'store/reducers/certificates';

export function CertificateForm() {
  const { t } = useTranslation();
  const { login, isClientLoading } = useLogin();
  const currentCertificate = useSelector(selectCurrentCertificate());
  const isSignLoading = useSelector(selectIsSignLoading());

  const location = useLocation();

  const handleSubmitClick = async () => {
    await login();
  };

  return (
    <div style={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CertificateSelector />

      <div className={s.buttonContainer}>
        <Button
          type="primary"
          className={s.button}
          onClick={handleSubmitClick}
          disabled={!currentCertificate || isSignLoading || isClientLoading}
        >
          {t(isSignLoading ? 'Loading...' : 'enter')}
        </Button>
      </div>
    </div>
  );
}
