import { ContractItem } from 'components/contracts/contract-item/contract-item';

import { AppRoutes } from 'config/routes';

import s from './Documents.module.scss';
import { AppDocument } from 'store/api/types/documents';

export const dataContrats: AppDocument[] = [
  {
    id: '3e5f95c1-764f-433c-bda6-b25196f31d2d',
    title: 'Соглашение о неразглашении конфиденциальной информации',
    number: '234234',
    docDate: '2023-11-02T00:00:00Z',
    template: {
      id: '9f90a0b5-9e7d-449c-9ada-fd14c697ea30',
    },
    version: 1,
    statusId: '22b6be53-ac81-4371-a1ed-5a0066ec4846',
    createdAt: '2023-11-02T17:22:18Z',
    updatedAt: '2023-11-02T17:22:59Z',
    content: 'content',
    type: 'type',
    validFrom: '01.01.2023',
    validTo: '01.01.2024',
    tbd: [
      {
        type: 'type',
        content: 'content',
      },
    ],
    tbs: 'tbs',
  },
];

export const Documents = () => {
  const { Home, Contracts } = AppRoutes.Dashboard;

  return (
    <div className={s['container']}>
      {dataContrats?.map((item) => (
        <ContractItem
          data={item}
          key={item.id.toString()}
          url={Home + Contracts.Home + Contracts.PreviewNewContract}
        />
      ))}
    </div>
  );
};
