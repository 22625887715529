import { useTranslation } from 'react-i18next';

import { useMeQuery } from 'store/api/profile';
import { ProfileAttribute } from 'store/api/types';
import s from './PersonalData.module.scss';
import { useGetMyOrganizationByIdQuery } from 'store/api/organizations';
import { useParams } from 'react-router-dom';

export const LeftInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['left-container']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

export const RightInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['right-content']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

const leftInfoWhitelist = ['organizational_unit', 'kpp', 'ogrn'];
const rightInfoWhitelist = ['phone', 'email', 'address', 'locality', 'inn_legal_entity'];

export const PersonalData = () => {
  const { id } = useParams();

  const { data: [userInfo] = [], isFetching } = useGetMyOrganizationByIdQuery(
    { id: id ?? '' },
    { skip: !id },
  );

  if (isFetching) {
    return null;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper-content']}>
        <LeftInfo
          attributes={userInfo?.attributes?.filter((item) =>
            leftInfoWhitelist.includes(item.name),
          )}
        />
        <RightInfo
          attributes={userInfo?.attributes.filter((item) =>
            rightInfoWhitelist.includes(item.name),
          )}
        />
      </div>
    </div>
  );
};
