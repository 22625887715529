import { gql } from 'graphql-request';

export const GET_ALL_SHIPMENT_REGISTRIES = gql`
  query App5182dd71_Document($filters: ShipmentRegistryFilters!) {
    app5182dd71_ShipmentRegistry {
      getList(shipmentRegistryFilters: $filters, pagination: { page: 1, limit: 20 }) {
        data {
          id
          version
          statusId
          number
          period
          buyerInn
          buyerKpp
          buyer
          document
          agreement
          total
          invoiceNumber
          invoiceDate
          invoiceTotal
          createdAt
          updatedAt
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
