import { gql } from 'graphql-request';

export const GET_ONE_OPERATIONAL_DOCUMETN = gql`
  query App5182dd71_Document($documentId: String!) {
    app5182dd71_OperationalRequest {
      getOne(id: $documentId) {
        id
        number
        agreementName
        version
        statusId
        orgOwnerId
        employeeOwner
        employeeReceiving
        requestDate
        shippingDate
        deliveryBasis
        supplyAddress
        goodsType
        quantity
        comment
        createdAt
        updatedAt
      }
    }
  }
`;
