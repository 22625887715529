import { Route, Routes } from 'react-router-dom';

import AllRequests from './all/All';
import { DetailRequest } from './detail-request/DetailRequest';

import { AppRoutes } from 'config/routes';
import { NewRequestPage } from './new-request';

function OperationalRequests() {
  const { NewRequest, RequestApproval } = AppRoutes.Dashboard.OperationalRequests;

  return (
    <Routes>
      <Route path="/" element={<AllRequests />} />
      <Route path="/:id/*" element={<DetailRequest />} />

      <Route path={NewRequest} element={<NewRequestPage />} />
      <Route path={RequestApproval + '/:id'} element={<NewRequestPage />} />

      <Route path={NewRequest + '/:id'} element={<NewRequestPage />} />
    </Routes>
  );
}

export default OperationalRequests;
