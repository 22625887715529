import { gql } from 'graphql-request';

export const GET_ONE_SHIPMENT_TASK = gql`
  query App5182dd71_Document($documentId: String!) {
    app5182dd71_ShipmentTask {
      getOne(id: $documentId) {
        id
        version
        statusId
        operationalRequest {
          id
          agreementName
          number
          requestDate
          shippingDate
          deliveryBasis
          supplyAddress
          goodsType
          quantity
          statusId
          employeeOwner
          employeeReceiving
          comment
        }
        statusId
        supplierExecutor
        buyerExecutor
        createdAt
        updatedAt
      }
    }
  }
`;
