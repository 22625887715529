import { gql } from 'graphql-request';

export const GET_ALL_SHIPMENT_TASKS = gql`
  query App5182dd71_Document(
    $agreementId: String
    $input: String
    $shippingDateBefore: String
    $shippingDateAfter: String
    $statusIds: [String!]
  ) {
    app5182dd71_ShipmentTask {
      getList(
        filters: {
          input: $input
          statusIds: $statusIds
          agreementId: $agreementId
          shippingDateBefore: $shippingDateBefore
          shippingDateAfter: $shippingDateAfter
        }
        pagination: { limit: 1000 }
      ) {
        data {
          id
          version
          operationalRequest {
            id
            agreementName
            number
            requestDate
            shippingDate
            deliveryBasis
            supplyAddress
            goodsType
            quantity
            statusId
            employeeOwner
            employeeReceiving
            comment
          }
          statusId
          supplierExecutor
          buyerExecutor
          createdAt
          updatedAt
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
