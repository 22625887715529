import { gql } from 'graphql-request';

export const UPDATE_SHIPMENT_TASK_DOCUMENT = gql`
  mutation App5182dd71_Document(
    $documentId: String!
    $input: app5182dd71_ShipmentTask_Update_Input!
  ) {
    app5182dd71_ShipmentTask {
      update(documentId: $documentId, input: $input) {
        id
      }
    }
  }
`;
