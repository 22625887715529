import cx from 'classnames';
import { HeaderPage } from 'components/header-page/header-page';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';

import { DocumentHeaderInfo } from 'components/document-header-info';
import { Loader } from 'components/loader/loader';
import { Formik, FormikValues } from 'formik';
import {
  useGetCoreDocumentsQuery,
  useSendToCorrectOperationalRequestMutation,
} from 'store/api/documents';
import { useAppDispatch } from 'store/hooks';
import { setFuelFormData } from 'store/reducers/contract.reducer';
import { prepareDate } from 'utils/date';
import {
  SchemeModificators,
  generateForm,
  generateInitialValues,
} from 'utils/sheme-parser/generate-form';

import { useCallback, useEffect } from 'react';
import styles from './NewRequest.module.scss';
import { useHandleMutation } from 'utils/handleMutation';
import { AppRoutes } from 'config/routes';
import { NewRequestPageTemplate } from './new-task-template';
import { useNewTask } from './use-new-task';

const FormResetter = ({
  resetForm,
  setStep,
  currentSelect,
}: {
  resetForm: () => void;
  setStep: (step: number) => void;
  currentSelect: { value: string; label: string };
}) => {
  useEffect(() => {
    resetForm();
    setStep(1);
  }, [currentSelect]);

  return null;
};

export const NewRequestPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const withHeader = true;
  const withFooter = true;

  const {
    step,
    steps,
    model,
    isLoading,
    setStep,
    handleSign,
    handleClose,
    isApproval,
    currentSelect,
    dropDownOptions,
    updateShipmentTask,
    getValidationSchema,
  } = useNewTask();

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [id ?? ''] },
    { skip: !id },
  );

  const [sendToCorrect, sendToCorrectMutation] = useSendToCorrectOperationalRequestMutation();

  const onSuccess = useCallback(() => {
    navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentTasks.Home);
  }, [navigate]);

  useHandleMutation({ ...sendToCorrectMutation, onSuccess });

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onSubmit = async (values: FormikValues) => {
    if (step < steps[steps?.length - 1]) {
      setStep(step + 1);
    } else {
      // if (isApproval && id && document) {
      //   handleSign(document.id, document.version);
      //   return;
      // }

      const modifiedValues = { ...values };
      const parameters: any = [];

      Object.keys(modifiedValues)?.forEach((key) => {
        if (key?.toLocaleLowerCase()?.includes('date')) {
          modifiedValues[key] = prepareDate(modifiedValues[key]);
        }
        if (currentSelect.scheme.find((item: any) => item.name === key).type === 'checkbox') {
          modifiedValues[key] = modifiedValues[key].toString();
        }
        if (
          currentSelect.scheme.find((item: any) => item.name === key).fieldTransform ===
          'parameter'
        ) {
          parameters.push({
            key: key,
            valStr: modifiedValues[key],
          });
          delete modifiedValues[key];
        }
      });

      dispatch(setFuelFormData(modifiedValues));

      if (currentSelect.value === 'ShipmentTask') {
        await updateShipmentTask({
          input: {
            employeeId: modifiedValues?.supplierExecutor ?? modifiedValues.buyerExecutor,
          },
          documentId: document?.id,
        });
      }
    }
  };

  const isSchemeExist = () => {
    return dropDownOptions?.reduce((acc, item) => {
      if (item.scheme) {
        return true;
      }
      return acc;
    }, false);
  };

  if (isFetching || !isSchemeExist()) {
    return <Loader size="medium" />;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      initialValues={generateInitialValues(model)}
    >
      {({ submitForm, resetForm, values }) => (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <FormResetter
              currentSelect={currentSelect}
              resetForm={resetForm}
              setStep={setStep}
            />
            {withHeader && !isApproval && (
              <HeaderPage title={t(id ? 'Задание на отгрузку' : 'New shipment task')} />
            )}
            {withHeader && isApproval && <DocumentHeaderInfo isLongTitle document={document} />}

            <div className={styles.contentBox}>
              <div className={styles.card}>
                <NewRequestPageTemplate>
                  {generateForm({
                    model,
                    step,
                    formValues: values,
                    modificators: isApproval ? [SchemeModificators.disabled] : ([] as any),
                  })}
                </NewRequestPageTemplate>
              </div>
            </div>

            {withFooter && (
              <div className={styles['footer']}>
                {step > 1 && (
                  <Button
                    className={cx(styles['button'], styles['button-back'])}
                    onClick={handlePrevStep}
                  >
                    {t('Back')}
                  </Button>
                )}

                <Button
                  displayType="outlined-tetriary"
                  className={cx(styles['button'])}
                  onClick={handleClose}
                >
                  {t('Cancel')}
                </Button>
                {isApproval && (
                  <Button
                    displayType="outlined-primary"
                    className={cx(styles['button'])}
                    onClick={() => {
                      sendToCorrect({ documentId: document.id });
                    }}
                  >
                    {t('Вернуть на корректировку')}
                  </Button>
                )}
                <Button className={cx(styles['button'])} onClick={submitForm}>
                  {isLoading || sendToCorrectMutation?.isLoading
                    ? t('Loading...')
                    : t(isApproval ? 'Назначить исполнителя' : 'Назначить исполнителя')}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
