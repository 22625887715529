import {
  FinishRecoveryRequest,
  StartAuthResponse,
  StartRecoveryRequest
} from '../models/api';
import jsonApi from './json-api';

const API_SECTION = 'auth';

function startRecovery({ login }: StartRecoveryRequest): Promise<StartAuthResponse> {
  return jsonApi.post<StartRecoveryRequest, StartAuthResponse>(
    `${API_SECTION}/recovery/password/start`,
    {
      login,
    },
  );
}

function finishRecovery({
  token,
  password,
}: FinishRecoveryRequest): Promise<StartAuthResponse> {
  return jsonApi.post<FinishRecoveryRequest, StartAuthResponse>(
    `${API_SECTION}/recovery/password`,
    {
      token,
      password,
    },
  );
}

export { finishRecovery, startRecovery };
