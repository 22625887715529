import { PreviewRequestPageTemplate } from 'components/contracts/new-request/preview-request-page-template';
import { Loader } from 'components/loader/loader';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOneShipmentTaskQuery, useGetShipmentRegistryQuery } from 'store/api/documents';
import styles from './Details.module.scss';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';

export const Details = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: shipmentRegistry = {}, isFetching } = useGetShipmentRegistryQuery(
    { documentId: id ?? '' },
    { skip: !id },
  );

  if (isFetching) {
    return <Loader size="ultra" />;
  }

  /*
  {
    "id": "2ba57732-623e-4f3d-bb5f-cdaa47c44264",
    "version": 1,
    "statusId": "6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d",
    "number": "УПН-6",
    "period": "Октябрь 2024",
    "buyerInn": "2311006961",
    "buyerKpp": "231101001",
    "buyer": "Сатурн",
    "document": "№2 от 2024-11-12",
    "agreement": "Соглашение 1/12.11.2024 к договору 2/12.11.2024",
    "total": 4200,
    "invoiceNumber": "20240912007828",
    "invoiceDate": "12.09.2024",
    "invoiceTotal": "554.17",
    "createdAt": "2024-11-14T06:24:41.877Z",
    "updatedAt": "2024-11-14T08:22:37.493Z"
}
  */

  const FIELDS = [
    'number',
    'period',
    'buyerInn',
    'buyerKpp',
    'buyer',
    'document',
    'agreement',
    'total',
    'invoiceNumber',
    'invoiceDate',
    'invoiceTotal',
    'createdAt',
    'updatedAt',
  ];

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>Реестр отгрузок по договору цессии</div>
        <div className={styles.contentBox}>
          <div className={styles.card}>
            <PreviewRequestPageTemplate>
              {Object.entries(shipmentRegistry ?? {})
                // Filter the fields to include only those in FIELDS
                .filter(([key]) => FIELDS.includes(key))
                // Sort by the order of fields in FIELDS array
                .sort((a, b) => FIELDS.indexOf(a[0]) - FIELDS.indexOf(b[0]))
                .map(([key, value]) => (
                  <div key={key} className={styles.section}>
                    <div className={styles.label}>{t(`ShipmentRegistry.${key}`)}</div>
                    <div className={styles.value}>
                      {(typeof value === 'object'
                        ? `${(value as any)?.[0]?.author ?? ''}\n ${
                            (value as any)?.[0]?.message ?? ''
                          }`
                        : key.toLocaleLowerCase()?.includes('date')
                        ? dayjs(value?.toString()).format(DefaultDateFormat)
                        : value?.toString()) ?? ''}
                    </div>
                  </div>
                ))}
            </PreviewRequestPageTemplate>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            onClick={() =>
              navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentRegistry.Home)
            }
            type="tetriary"
            displayType="outlined-tetriary"
          >
            Отмена
          </Button>
        </div>
      </div>
    </div>
  );
};
