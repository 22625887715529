import api from './api';

async function downloadDocument(id: string, name: string) {
  try {
    const response = await api(`download/organization/document/${id}`);

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading the document:', error);
  }
}

async function downloadShipmentRegistryDocument(id: string) {
  try {
    const response = await api(`app5182dd71/download-file?documentId=${id}`);

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'file.xml';
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading the document:', error);
  }
}

export { downloadDocument, downloadShipmentRegistryDocument };
