import { Input } from 'antd';
import cx from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';
import { AsideFilter } from './filter/filter';

import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';

import { Loader } from 'components/loader/loader';
import { useGetShipmentTasksQuery, useRevokeShipmentTasksMutation } from 'store/api/documents';

import { Icons } from 'components/icons';
import { NotFound, NotFoundContainer } from 'components/not-found';
import { useAlert } from 'react-alert';
import { useDebounce } from 'usehooks-ts';
import { useHandleMutation } from 'utils/handleMutation';
import s from './All.module.scss';
import { AsideTableSettings } from './aside-table-settings/table-settings';
import { FiltersValues } from './filter/config';
import { ShipmentTasksTable } from './ShipmentTasksTable';

export const Label = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['label-container']}>
      <span className={s['label-text']}>{t(text)}</span>
    </div>
  );
};

const DEFAULT_COLUMNS = [
  'number',
  'agreementName',
  'requestDate',
  'shippingDate',
  'deliveryBasis',
  'goodsType',
  'quantity',
  'statusId',
  'employeeOwner',
  'employeeReceiving',
];

function AllTasks() {
  const alert = useAlert();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');
  const filterDebounced = useDebounce(filter, 1000);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isTableSettingsVisible, setTableSettingsVisible] = useState<boolean>(false);
  const [filterStatuses, setFilterStatuses] = useState<string[]>();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [selectedColumns, setSelectedColumns] = useState(DEFAULT_COLUMNS);

  const excludeColumns = ['id', 'version', 'orgOwnerId'];

  const [filterValues, setFilterValues] = useState<Partial<FiltersValues>>({});

  const { data, isFetching } = useGetShipmentTasksQuery({
    statusIds: filterStatuses?.length ? filterStatuses : undefined,
    agreementId: filterValues?.agreement,
    searchQuery: filterDebounced,
    shippingDateAfter: filterValues?.startDate,
    shippingDateBefore: filterValues?.endDate,
  });

  const [revokeRequest, revokeMutation] = useRevokeShipmentTasksMutation();

  const onSuccess = useCallback(() => {
    alert.success('Shipment tasks revoked');
  }, [alert]);

  useHandleMutation({ ...revokeMutation, onSuccess });

  const handleFilter = (values: FiltersValues) => {
    const [startDate, endDate] = JSON.parse(values.period ?? '[]');

    const modified = {
      ...values,
      agreement: values?.agreement === 'all' ? undefined : values?.agreement,
      startDate,
      endDate,
    };
    setFilterValues(modified);
  };

  const handleRevoke = () => {
    revokeRequest({ documentIds: selectedRows });
    setSelectedRows([]);
  };

  const onSelectRow = (id: string) => {
    if (selectedRows.some((item) => item === id)) {
      setSelectedRows(selectedRows.filter((item) => item !== id));
      return;
    }

    setSelectedRows([...selectedRows, id]);
  };

  const handleFilterValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilter(value);
  };

  const columns = useMemo(
    () =>
      Object.keys(data?.[0]?.operationalRequest ?? {})?.map((item) => ({
        key: item,
        label: item,
      })),
    [data],
  );

  const handleColumnSelection = (values: any) => {
    const newSelectedColumns = Object.entries(values)
      .filter(([key, value]) => !!value)
      .sort((a, b) => DEFAULT_COLUMNS.indexOf(a[0]) - DEFAULT_COLUMNS.indexOf(b[0]))
      .map(([key]) => key);

    setSelectedColumns(newSelectedColumns);
    localStorage.setItem('selectedColumns', JSON.stringify(newSelectedColumns));
  };

  const handleResetToDefaults = () => {
    setSelectedColumns(DEFAULT_COLUMNS);
    localStorage.setItem('selectedColumns', JSON.stringify(DEFAULT_COLUMNS));
  };

  useEffect(() => {
    const savedColumns = localStorage.getItem('selectedColumns');
    if (savedColumns) {
      setSelectedColumns(JSON.parse(savedColumns));
    }
  }, []);

  return (
    <>
      <span className={s['title']}>{t('Shipment tasks')}</span>
      <div className={s['container-content']}>
        <div className={s['header']}>
          <div className={s['wrapper-search']}>
            <div className={s['search']}>
              <Input
                allowClear
                value={filter ?? ''}
                prefix={<SearchIcon />}
                placeholder={t('Search...')}
                onChange={handleFilterValue}
                style={{ borderRadius: '0px', height: '40px' }}
              />
            </div>
            <Button
              icon={<FilterIcon />}
              onClick={() => setIsFilter(true)}
              className={cx(s['button'])}
              displayType="outlined-tetriary"
            >
              {t('Filter')}
            </Button>

            <Button
              icon={<Icons.SettingsTable />}
              onClick={() => setTableSettingsVisible(true)}
              className={cx(s['button'], s.buttonSettings)}
              displayType="outlined-tetriary"
            />
          </div>

          <div className={s['wrapper-button']}>
            <Button
              disabled={!selectedRows.length}
              onClick={handleRevoke}
              displayType="outlined-secondary"
              className={cx(s['button'])}
            >
              {t(revokeMutation?.isLoading ? 'Loading...' : 'Revoke')}
            </Button>
          </div>
        </div>
        <div className={s['main']}>
          {isFetching ? (
            <Loader size="big" />
          ) : (
            <>
              {data?.length === 0 ? (
                <NotFoundContainer>
                  <NotFound
                    notFoundTitle=""
                    hasSearch={false}
                    title="Задания на отгрузку"
                    subtitle="отсутствуют"
                  />
                </NotFoundContainer>
              ) : (
                <ShipmentTasksTable
                  selectedRows={selectedRows}
                  onSelectRow={onSelectRow}
                  data={data?.map((item: any) => ({
                    taskId: item.id,
                    ...item.operationalRequest,
                    statusId: item.statusId,
                  }))}
                  selectedColumns={selectedColumns}
                />
              )}
            </>
          )}
        </div>
      </div>
      <AsideFilter
        isOpen={isFilter}
        onClose={() => setIsFilter(false)}
        onSubmitForm={handleFilter}
        status={filterStatuses ?? []}
        onChangeStatus={setFilterStatuses}
      />

      <AsideTableSettings
        isOpen={isTableSettingsVisible}
        onClose={() => setTableSettingsVisible(false)}
        columns={columns?.filter((item) => !excludeColumns.includes(item.key))}
        selectedColumns={selectedColumns}
        onSubmitForm={handleColumnSelection}
        onResetToDefaults={handleResetToDefaults}
      />
    </>
  );
}

export default AllTasks;
