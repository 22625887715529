import { gql } from 'graphql-request';

export const GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME = gql`
  query App5182dd71_Document($documentId: String) {
    app5182dd71_OperationalRequest {
      getTemplate(documentId: $documentId) {
        template {
          step
          name
          type
          required
          disabled
          isSearchable
          placeholder
          fieldTransform
          fieldType
          isVisible
          isVisibleDependency
          stepLabel
          validation
          optionsDependency
          value
          label {
            ru
            en
          }
          optionsDependentSelect {
            value
            options {
              label
              value
            }
          }
          options {
            label
            value
          }
        }
      }
    }
  }
`;
