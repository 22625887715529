import { gql } from 'graphql-request';

export const GET_ONE_SHIPMENT_REGISTRY = gql`
  query App5182dd71_Document($documentId: String!) {
    app5182dd71_ShipmentRegistry {
      getOne(id: $documentId) {
        id
        version
        statusId
        number
        period
        buyerInn
        buyerKpp
        buyer
        document
        agreement
        total
        invoiceNumber
        invoiceDate
        invoiceTotal
        createdAt
        updatedAt
      }
    }
  }
`;
