import { gql } from 'graphql-request';

export const SEND_TO_CORRECT_OPERATIONAL_REQUEST = gql`
  mutation App5182dd71_Document($documentId: String!) {
    app5182dd71_OperationalRequest {
      sendToCorrect(documentId: $documentId) {
        status
        message {
          ru
          en
        }
      }
    }
  }
`;
