import { ReactComponent as LoginIcon } from 'assets/images/login.svg';
import { useTranslation } from 'react-i18next';
import s from './LoginPage.module.scss';

import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { CheckClientModal } from 'components/modal/checkClient';
import { AppRoutes } from 'config/routes';
import { useLocation } from 'react-router-dom';
import { CertificateForm } from './CertificateForm';
import { EmailLoginForm } from './EmailLoginForm';
import { PinCodeForm } from './PinCodeForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { StaffInviteForm } from './StaffInviteForm';

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'LOGIN (E-MAIL)',
    path: AppRoutes.Auth.Home + AppRoutes.Auth.Login + AppRoutes.Auth.Email,
    disabled: false,
  },
  {
    id: 1,
    label: 'PHONE',
    path: '/phone',
    disabled: true,
  },
  {
    id: 2,
    label: 'certificate',
    path: AppRoutes.Auth.Home + AppRoutes.Auth.Login + AppRoutes.Auth.Certificate,
  },
];

export function LoginPage() {
  const { t } = useTranslation();

  const location = useLocation();
  const isEmailLogin = location.pathname.endsWith(AppRoutes.Auth.Email);
  const isCertificateLogin = location.pathname.endsWith(AppRoutes.Auth.Certificate);
  const isPinCodeForm = location.pathname.endsWith(AppRoutes.Auth.PinCode);
  const isForgotPassword = location.pathname.endsWith(AppRoutes.Auth.ForgotPassword);
  const isStaffInvite = location.pathname.includes(AppRoutes.Auth.StaffInvite);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.header}>
            {t('Connects')} <br /> {t('Business')}
          </div>
          <div className={s.subtitle}>
            {t(
              'Welcome to Contracteum, a platform for secure digital transactions under your full control',
            )}
          </div>

          <div className={s.entranceContainer}>
            <h1 className={s.entrance}>
              <div>{t('entrance')} </div>
              <LoginIcon />
            </h1>
          </div>
        </div>

        <div className={s.secondBlock}>
          <div>
            <HeaderTabs type="detail" tabs={tabsContent} />
          </div>

          {false && (
            <span className={s.description}>
              {t('To register a company, log in with your digital signature certificate.')}
            </span>
          )}

          <div className={s?.['full-width']}>
            {isEmailLogin && <EmailLoginForm />}
            {isCertificateLogin && <CertificateForm />}
            {isPinCodeForm && <PinCodeForm />}
            {isForgotPassword && <ForgotPasswordForm />}
            {isStaffInvite && <StaffInviteForm />}
          </div>
        </div>
      </div>

      <div className={s.bottom}></div>

      <CheckClientModal isCertificate={!!isCertificateLogin} />
    </div>
  );
}
