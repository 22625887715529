import { EmailStartAuthRequest } from 'models/api/auth/email-start-auth-request';
import { RefreshRequest } from 'models/api/auth/refresh-request.model';
import { RefreshResponse } from 'models/api/auth/refresh-response.model';
import {
  LoginRequest,
  LoginResponse,
  StartAuthRequest,
  StartAuthResponse,
} from '../models/api';
import jsonApi from './json-api';

const API_SECTION = 'auth';

function loginStart(cert_data: string): Promise<StartAuthResponse> {
  return jsonApi.post<StartAuthRequest, StartAuthResponse>(`${API_SECTION}/login/pki/start`, {
    cert_data,
  });
}

function login(
  token: string,
  signature: string,
  detachedSignature: string,
): Promise<LoginResponse> {
  return jsonApi.post<LoginRequest, LoginResponse>(`${API_SECTION}/login/pki`, {
    token,
    signature,
    detachedSignature,
  });
}

function emailLoginStart({
  login,
  password,
}: EmailStartAuthRequest): Promise<StartAuthResponse> {
  return jsonApi.post<EmailStartAuthRequest, StartAuthResponse>(
    `${API_SECTION}/login/psw/start`,
    {
      login,
      password,
    },
  );
}

function emailLoginFinish({ login, password }: EmailStartAuthRequest): Promise<LoginResponse> {
  return jsonApi.post<EmailStartAuthRequest, LoginResponse>(`${API_SECTION}/login/psw`, {
    login,
    password,
  });
}

function refreshToken(refresh_token: string): Promise<RefreshResponse> {
  return jsonApi.post<RefreshRequest, RefreshResponse>(`${API_SECTION}/openid-connect/token`, {
    refresh_token,
  });
}

export { emailLoginFinish, emailLoginStart, login, loginStart, refreshToken };

