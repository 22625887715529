import { Route, Routes } from 'react-router-dom';

import AllContracts from './all-contract/AllContract';
import { DetailContract } from './detail-contract/DetailContract';

import { NewContractPreview } from 'components/contracts/new-contract-preview/NewContractPreview';
import { AppRoutes } from 'config/routes';
import { NewContractPage } from './new-contract';

function Contracts() {
  const { NewContract, ContractApproval, PreviewNewContract } = AppRoutes.Dashboard.Contracts;

  return (
    <Routes>
      <Route path="/" element={<AllContracts />} />
      <Route path="/:id/*" element={<DetailContract />} />

      <Route path={NewContract} element={<NewContractPage />} />
      <Route path={ContractApproval + '/:id'} element={<NewContractPage />} />

      <Route
        path={NewContract + PreviewNewContract + '/:id'}
        element={<NewContractPreview />}
      />
      <Route path={PreviewNewContract + '/:id'} element={<NewContractPreview />} />
    </Routes>
  );
}

export default Contracts;
