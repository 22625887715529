import { InputField } from 'components/common';
import { Icons } from 'components/icons';
import { PasswordRequirements } from 'components/password-requirements';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { Form, Formik, FormikHelpers } from 'formik';
import useEmailStaffInvite from 'hooks/use-email-staff-invite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import s from './ForgotPasswordForm.module.scss';

interface ForgotPasswordFormValues {
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export const StaffInviteForm = () => {
  const { t } = useTranslation();
  const { setPassword, isLoading } = useEmailStaffInvite();

  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isPasswordChangedError, setIsPasswordChangedError] = useState(false);

  const initialValues: ForgotPasswordFormValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password must be at least 8 characters'))
      .max(64, t('Password must be less than 64 characters'))
      .matches(/[a-zа-яё]/, t('Password must contain at least one lowercase letter'))
      .matches(/[A-ZА-ЯЁ]/, t('Password must contain at least one uppercase letter')),
    confirmPassword: Yup.string()
      .required(t('Confirm password is required'))
      .oneOf([Yup.ref('password')], t('Passwords must match')),
  });

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>,
  ) => {
    const { password } = values ?? {};
    if (password) {
      try {
        await setPassword({ password });
        setIsPasswordChanged(true);
      } catch (error) {
        setIsPasswordChangedError(true);
      }
    }
    actions.setSubmitting(false);
  };

  return (
    <div className={s.container}>
      <>
        {isPasswordChanged && (
          <div style={{ justifyContent: 'center' }} className={s.form}>
            <div className={s.successMessage}>
              <Icons.SuccessMark />
              <h2>{t('Password Setted')}</h2>
              <p>{t('Your password has been successfully setted.')}</p>

              <div className={s.buttonContainer}>
                <Button
                  type="primary"
                  onClick={() =>
                    (window.location.href =
                      AppRoutes.Auth.Home + AppRoutes.Auth.Login + AppRoutes.Auth.Email)
                  }
                >
                  {t('Go to Login')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {!isPasswordChanged && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form className={s.form}>
                <InputField
                  name="password"
                  type="password"
                  label={t('New Password')}
                  placeholder={t('Enter your new password')}
                  required
                />
                <InputField
                  name="confirmPassword"
                  type="password"
                  label={t('Confirm Password')}
                  placeholder={t('Confirm your new password')}
                  required
                />
                <div className={s.passwordRequirements}>
                  <PasswordRequirements password={values.password!} />
                </div>

                {isPasswordChangedError && (
                  <div className={s.errorMessage}>
                    <Icons.Remove width={101} height={101} />
                    <h2>{t('Password Change Error')}</h2>
                    <p>{t('Unxepected Server Error')}</p>
                  </div>
                )}
                <div className={s.buttonContainer}>
                  <Button type="primary" disabled={isSubmitting}>
                    {isLoading ? t('Loading...') : t('Set Password')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    </div>
  );
};
