import cx from 'classnames';
import { AppRoutes } from 'config/routes';
import { useState } from 'react';

import { Tab } from 'components/header-tabs/header-tabs';
import { AsideProfile } from 'components/modal/aside-profile/aside-profile';
import { createButtonSettings } from 'modules/dashboard/counterparties/counterparty-detail/CounterpatyDetail';

import { PersonalData } from './persona-data/PersonalData';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';

import s from './Content.module.scss';

const { Home, Profile } = AppRoutes.Dashboard;

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'Personal data',
    path: Home + Profile.Home + Profile.PersonalData,
  },
  {
    id: 1,
    label: 'Authority',
    path: Home + Profile.Home + Profile.Authority,
  },
];

export const ContentProfile = () => {
  const classesButton = cx(s['button']);

  const [isAside, setIsAside] = useState(false);

  const handleAsideOpen = () => {
    setIsAside(true);
  };
  const handleAsideClose = () => {
    setIsAside(false);
  };

  const buttonSettings = createButtonSettings({
    text: 'Edit',
    icon: <EditIcon />,
    type: 'primary',
    disabled: false,
    className: classesButton,
    onClick: handleAsideOpen,
  });

  return (
    <div className={s['container']}>
      <PersonalData />

      <AsideProfile isOpen={isAside} onClick={handleAsideClose} />
    </div>
  );
};
