import { createApi } from '@reduxjs/toolkit/query/react';

import { prepareDayjs } from 'utils/date';
import { REVOKE_CONTROL_CARDS } from './mutations/documents/control-cards/revoke.control-cards';
import { SEND_TO_CORRECT_CONTROL_CARD } from './mutations/documents/control-cards/send.to.correct.control-card';
import { UPDATE_CONTROL_CARD_DOCUMENT } from './mutations/documents/control-cards/update.control-card';
import { CREATE_AGRO_PODUCTION_DOCUMENT } from './mutations/documents/create.agro.document';
import { CREATE_CESSION_DOCUMENT } from './mutations/documents/create.cession.document';
import { CREATE_DOCUMENT } from './mutations/documents/create.document';
import { CREATE_LICENSE_DOCUMENT } from './mutations/documents/create.license.document';
import { CREATE_SUPPLY_DOCUMENT } from './mutations/documents/create.supply.document';
import { CREATE_OPERATIONAL_REQUEST_DOCUMENT } from './mutations/documents/operational-requests/create.operational-request';
import { REVOKE_OPERATIONAL_REQUEST } from './mutations/documents/operational-requests/revoke.operational-request';
import { SEND_TO_CORRECT_OPERATIONAL_REQUEST } from './mutations/documents/operational-requests/send.to.correct.operational-request';
import { UPDATE_SHIPMENT_REPORT_DOCUMENT } from './mutations/documents/shipment-reports/update.shipment-report';
import { REVOKE_SHIPMENT_TASKS } from './mutations/documents/shipment-tasks/revoke.shipment-tasks';
import { SEND_TO_CORRECT_SHIPMENT_TASK } from './mutations/documents/shipment-tasks/send.to.correct.shipment-task';
import { UPDATE_SHIPMENT_TASK_DOCUMENT } from './mutations/documents/shipment-tasks/update.shipment-task';
import { SIGN_DOCUMENT } from './mutations/documents/sign.document';
import {
  CreateAgroProductionDocumentResponse,
  CreateCessionDocumentResponse,
  CreateDocumentResponse,
  CreateLicenseDocumentResponse,
  CreateOperationalRequestDocumentResponse,
  CreateSupplyDocumentResponse,
  SignDocumentInput,
  UpdateDocumentInput,
  UpdateDocumentResponse,
} from './mutations/documents/types';
import { UPDATE_DOCUMENT } from './mutations/documents/update.document';
import { GET_ALL_CONTROL_CARDS } from './queries/documents/control-cards/get.all.control-cards';
import { GET_CONTROL_CARD_SCHEME } from './queries/documents/control-cards/get.control-card.scheme';
import { GET_ONE_CONTROL_CARD } from './queries/documents/control-cards/get.one.control-card';
import { GET_CORE_DOCUMENT_STATUSES } from './queries/documents/get.core.document.statuses';
import { GET_CORE_DOCUMENTS } from './queries/documents/get.core.documents';
import { GET_CORE_DOCUMENTS_LIST } from './queries/documents/get.core.documents.list';
import { GET_DOCUMENT_ARGROPRODUCTION_SCHEME } from './queries/documents/get.document.agroproduction.scheme';
import { GET_CESSION_DOCUMENT_SCHEME } from './queries/documents/get.document.cession.scheme';
import {
  GET_DOCUMENT_LICECES_SCHEME,
  LICENCE_TEMPLATE_ID,
} from './queries/documents/get.document.licences.scheme';
import { GET_FUEL_DOCUMENT_SCHEME } from './queries/documents/get.document.scheme';
import { GET_SUPPLY_DOCUMENT_SCHEME } from './queries/documents/get.document.supply.scheme';
import { GET_ALL_OPERATIONAL_DOCUMETNS } from './queries/documents/operational-requests/get.all.operational-requests';
import { GET_ONE_OPERATIONAL_DOCUMETN } from './queries/documents/operational-requests/get.one.operational-request';
import { GET_ONE_OPERATIONAL_REQUEST_FILTERS } from './queries/documents/operational-requests/get.operational-request.filters';
import { GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME } from './queries/documents/operational-requests/get.operational-request.scheme';
import { GET_ALL_SHIPMENT_REGISTRIES } from './queries/documents/shipment-registry/get.all.shipment-registries';
import { GET_ONE_SHIPMENT_REGISTRY } from './queries/documents/shipment-registry/get.one.shipment-registry';
import { GET_SHIPMENT_REGISTRY_SCHEME } from './queries/documents/shipment-registry/get.shipment-registry.scheme';
import { GET_ALL_SHIPMENT_REPORTS } from './queries/documents/shipment-reports/get.all.shipment-reports';
import { GET_ONE_SHIPMENT_REPORT } from './queries/documents/shipment-reports/get.one.shipment-report';
import { GET_ALL_SHIPMENT_TASKS } from './queries/documents/shipment-tasks/get.all.shipment-tasks';
import { GET_ONE_SHIPMENT_TASK } from './queries/documents/shipment-tasks/get.one.shipment-task';
import { GET_SHIPMENT_TASK_SCHEME } from './queries/documents/shipment-tasks/get.shipment-tasks.scheme';
import {
  DocumentStatusesResponse,
  DocumentsListResponse,
  DocumentsRequest,
  DocumentsResponse,
} from './queries/documents/types';
import { baseQuery } from './query';
import { AppDocument, DocumentStatus } from './types/documents';

export const DocumentsTags = {
  List: 'List',
  ByIds: 'ByIds',
};
const tagTypes = [DocumentsTags.List, DocumentsTags.ByIds];

export const documentsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'documentsApi',
  endpoints: (builder) => ({
    getCoreDocuments: builder.query<AppDocument[], DocumentsRequest>({
      query: ({ ids }) => ({
        document: GET_CORE_DOCUMENTS,
        variables: {
          documentIds: ids,
          outputFormat: 'xml',
        },
      }),
      transformResponse: (rawResponse: DocumentsResponse) => {
        return rawResponse?.coreDocuments.get;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getCoreDocumentsNoCache: builder.mutation<AppDocument[], DocumentsRequest>({
      query: ({ ids }) => ({
        document: GET_CORE_DOCUMENTS,
        variables: {
          documentIds: ids,
          outputFormat: 'xml',
        },
      }),
      transformResponse: (rawResponse: DocumentsResponse) => {
        return rawResponse?.coreDocuments.get;
      },
    }),
    getFuelDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_FUEL_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app70e9b8a9_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getLicenseDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_LICECES_SCHEME,
        variables: {
          documentId,
          templateId: LICENCE_TEMPLATE_ID.Sublicense,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5705572d_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getPartnerDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_LICECES_SCHEME,
        variables: {
          documentId,
          templateId: LICENCE_TEMPLATE_ID.Partner,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5705572d_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getAgroProductionDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_ARGROPRODUCTION_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app36c5da51_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getCoreDocumentByIds: builder.mutation<AppDocument[], DocumentsRequest>({
      query: ({ ids }) => ({
        document: GET_CORE_DOCUMENTS,
        variables: {
          documentIds: ids,
          outputFormat: 'xml',
        },
      }),
      transformResponse: (rawResponse: DocumentsResponse) => {
        return rawResponse?.coreDocuments.get;
      },
    }),
    getDocumentsList: builder.query<
      AppDocument[],
      { searchText?: string; statuses?: Array<string> }
    >({
      query: ({ statuses, searchText }) => ({
        document: GET_CORE_DOCUMENTS_LIST,
        variables: {
          searchText,
          statuses: statuses ?? [
            '22b6be53-ac81-4371-a1ed-5a0066ec4846',
            '6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d',
            'e622b09f-9905-465d-8053-733fa24c6953',
          ],
          signatureTypes: ['xmldsig', 'pcks7'],
        },
      }),
      transformResponse: (rawResponse: DocumentsListResponse) => {
        return rawResponse?.coreDocuments.query.documents;
      },
      providesTags: [DocumentsTags.List],
    }),

    getDocumentStatuses: builder.query<DocumentStatus[], void>({
      query: () => ({
        document: GET_CORE_DOCUMENT_STATUSES,
      }),
      transformResponse: (rawResponse: DocumentStatusesResponse) => {
        return rawResponse?.coreDocuments.getStatuses;
      },
    }),

    createFuelDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.create?.id;
      },
    }),
    createLicenseDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_LICENSE_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateLicenseDocumentResponse) => {
        return rawResponse?.app5705572d_Document?.create?.id;
      },
    }),
    createAgroProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_AGRO_PODUCTION_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
    }),

    updateDocument: builder.mutation<string, UpdateDocumentInput>({
      query: (input) => ({
        document: UPDATE_DOCUMENT,
        variables: input,
      }),
      transformResponse: (rawResponse: UpdateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.update?.id;
      },
    }),
    signDocument: builder.mutation<string, SignDocumentInput>({
      query: ({ documentId, documentVersion, signature, detachedSignature }) => ({
        document: SIGN_DOCUMENT,
        variables: {
          documentId,
          documentVersion,
          signature,
          detachedSignature,
        },
      }),
      transformResponse: (rawResponse: UpdateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.update?.id;
      },
      invalidatesTags: [DocumentsTags.List, DocumentsTags.ByIds],
    }),
    getSupplyDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_SUPPLY_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app12d801b5_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    createSupplyProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_SUPPLY_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateSupplyDocumentResponse) => {
        return rawResponse?.app12d801b5_Document?.create?.id;
      },
    }),

    getCessionDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_CESSION_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app1d1b2841_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    createCessionProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_CESSION_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateCessionDocumentResponse) => {
        return rawResponse?.app1d1b2841_Document?.create?.id;
      },
    }),

    // operational requests

    getOperationalRequets: builder.query<any, any>({
      query: ({
        searchQuery,
        agreementId,
        shippingDateAfter,
        shippingDateBefore,
        statusIds,
      }) => ({
        document: GET_ALL_OPERATIONAL_DOCUMETNS,
        variables: {
          agreementId: agreementId?.length ? agreementId : null,
          shippingDateAfter: shippingDateAfter?.length ? prepareDayjs(shippingDateAfter) : null,
          shippingDateBefore: shippingDateBefore?.length
            ? prepareDayjs(shippingDateBefore)
            : null,
          input: searchQuery?.length ? searchQuery : null,
          statusIds: statusIds ?? [
            '22b6be53-ac81-4371-a1ed-5a0066ec4846',
            '6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d',
            'e622b09f-9905-465d-8053-733fa24c6953',
            '08e2f259-9ae2-4103-ad8f-01f0567bb7ce',
          ],
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_OperationalRequest?.getList?.data;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getOperationalRequetsFilters: builder.query<any, any>({
      query: () => ({
        document: GET_ONE_OPERATIONAL_REQUEST_FILTERS,
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_OperationalRequest?.getFilterOptions;
      },
    }),

    getOperationalDocument: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_ONE_OPERATIONAL_DOCUMETN,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_OperationalRequest?.getOne;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getOperationalRequestDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_OperationalRequest?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    createOperationalRequestProductionDocument: builder.mutation<
      string,
      { input: any; documentId?: string }
    >({
      query: ({ input, documentId }) => ({
        document: CREATE_OPERATIONAL_REQUEST_DOCUMENT,
        variables: { input, documentId },
      }),
      transformResponse: (rawResponse: CreateOperationalRequestDocumentResponse) => {
        return rawResponse?.app5182dd71_OperationalRequest?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    revokeOperationalRequest: builder.mutation<string, { documentIds: string[] }>({
      query: ({ documentIds }) => ({
        document: REVOKE_OPERATIONAL_REQUEST,
        variables: { documentIds },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    sendToCorrectOperationalRequest: builder.mutation<string, { documentId: string }>({
      query: ({ documentId }) => ({
        document: SEND_TO_CORRECT_OPERATIONAL_REQUEST,
        variables: { documentId },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    // shipment tasks

    getShipmentTasks: builder.query<any, any>({
      query: ({
        searchQuery,
        agreementId,
        shippingDateAfter,
        shippingDateBefore,
        statusIds,
      }) => ({
        document: GET_ALL_SHIPMENT_TASKS,
        variables: {
          agreementId: agreementId?.length ? agreementId : null,
          shippingDateAfter: shippingDateAfter?.length ? prepareDayjs(shippingDateAfter) : null,
          shippingDateBefore: shippingDateBefore?.length
            ? prepareDayjs(shippingDateBefore)
            : null,
          input: searchQuery?.length ? searchQuery : null,
          statusIds: statusIds ?? [
            '51520be1-ed8f-4b1a-afbe-d3dc5dfe3a32',
            '22b6be53-ac81-4371-a1ed-5a0066ec4846',
            '6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d',
            'e622b09f-9905-465d-8053-733fa24c6953',
            '08e2f259-9ae2-4103-ad8f-01f0567bb7ce',
          ],
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentTask?.getList?.data;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getOneShipmentTask: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_ONE_SHIPMENT_TASK,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentTask?.getOne;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getShipmentTasksScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_SHIPMENT_TASK_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentTask?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    updateShipmentTask: builder.mutation<string, { input: any; documentId?: string }>({
      query: ({ input, documentId }) => ({
        document: UPDATE_SHIPMENT_TASK_DOCUMENT,
        variables: { input, documentId },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentTask?.update?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    revokeShipmentTasks: builder.mutation<string, { documentIds: string[] }>({
      query: ({ documentIds }) => ({
        document: REVOKE_SHIPMENT_TASKS,
        variables: { documentIds },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    sendToCorrectShipmentTask: builder.mutation<string, { documentId: string }>({
      query: ({ documentId }) => ({
        document: SEND_TO_CORRECT_SHIPMENT_TASK,
        variables: { documentId },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    // Control Cards

    getControlCards: builder.query<any, any>({
      query: ({
        searchQuery,
        agreementId,
        shippingDateAfter,
        shippingDateBefore,
        statusIds,
      }) => ({
        document: GET_ALL_CONTROL_CARDS,
        variables: {
          agreementId: agreementId?.length ? agreementId : null,
          shippingDateAfter: shippingDateAfter?.length ? prepareDayjs(shippingDateAfter) : null,
          shippingDateBefore: shippingDateBefore?.length
            ? prepareDayjs(shippingDateBefore)
            : null,
          input: searchQuery?.length ? searchQuery : null,
          statusIds: statusIds ?? [
            '22b6be53-ac81-4371-a1ed-5a0066ec4846',
            '6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d',
            'e622b09f-9905-465d-8053-733fa24c6953',
            '08e2f259-9ae2-4103-ad8f-01f0567bb7ce',
          ],
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ControlCard?.getList?.data;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getOneControlCard: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_ONE_CONTROL_CARD,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ControlCard?.getOne;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getControlCardScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_CONTROL_CARD_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ControlCard?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    updateControlCard: builder.mutation<string, { input: any; documentId?: string }>({
      query: ({ input, documentId }) => ({
        document: UPDATE_CONTROL_CARD_DOCUMENT,
        variables: { input, documentId },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ControlCard?.update?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    revokeControlCards: builder.mutation<string, { documentIds: string[] }>({
      query: ({ documentIds }) => ({
        document: REVOKE_CONTROL_CARDS,
        variables: { documentIds },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    sendToCorrectControlCard: builder.mutation<string, { documentId: string }>({
      query: ({ documentId }) => ({
        document: SEND_TO_CORRECT_CONTROL_CARD,
        variables: { documentId },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    // Shipment Reports

    getShipmentReports: builder.query<any, any>({
      query: ({ searchQuery }) => ({
        document: GET_ALL_SHIPMENT_REPORTS,
        variables: {
          input: searchQuery?.length ? searchQuery : null,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentReport?.getList?.data;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    getOneShipmentReport: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_ONE_SHIPMENT_REPORT,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentReport?.getOne;
      },
      providesTags: [DocumentsTags.ByIds],
    }),

    updateShipmentReport: builder.mutation<string, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: UPDATE_SHIPMENT_REPORT_DOCUMENT,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ControlCard?.update?.id;
      },
      invalidatesTags: [DocumentsTags.ByIds],
    }),

    // Shipment Registry

    getShipmentRegistries: builder.query<any, any>({
      query: () => ({
        document: GET_ALL_SHIPMENT_REGISTRIES,
        variables: {
          filters: {},
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentRegistry?.getList?.data;
      },
    }),

    getShipmentRegistry: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_ONE_SHIPMENT_REGISTRY,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentRegistry?.getOne;
      },
    }),

    getShipmentRegistryTemplate: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_SHIPMENT_REGISTRY_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_ShipmentRegistry?.getTemplate?.template;
      },
    }),

    // end
  }),
});

export const {
  useSignDocumentMutation,
  useGetControlCardsQuery,
  useGetCoreDocumentsQuery,
  useGetDocumentsListQuery,
  useGetShipmentTasksQuery,
  useGetOneControlCardQuery,
  useUpdateDocumentMutation,
  useGetShipmentReportsQuery,
  useGetOneShipmentTaskQuery,
  useGetDocumentStatusesQuery,
  useGetShipmentRegistryQuery,
  useGetOneShipmentReportQuery,
  useUpdateControlCardMutation,
  useGetControlCardSchemeQuery,
  useRevokeControlCardsMutation,
  useGetOperationalRequetsQuery,
  useCreateFuelDocumentMutation,
  useGetFuelDocumentSchemeQuery,
  useUpdateShipmentTaskMutation,
  useGetShipmentRegistriesQuery,
  useGetOperationalDocumentQuery,
  useGetShipmentTasksSchemeQuery,
  useRevokeShipmentTasksMutation,
  useGetCoreDocumentByIdsMutation,
  useUpdateShipmentReportMutation,
  useGetSupplyDocumentSchemeQuery,
  useCreateLicenseDocumentMutation,
  useGetLicenseDocumentSchemeQuery,
  useGetPartnerDocumentSchemeQuery,
  useGetCessionDocumentSchemeQuery,
  useGetCoreDocumentsNoCacheMutation,
  useSendToCorrectControlCardMutation,
  useRevokeOperationalRequestMutation,
  useGetShipmentRegistryTemplateQuery,
  useSendToCorrectShipmentTaskMutation,
  useGetOperationalRequetsFiltersQuery,
  useCreateAgroProductionDocumentMutation,
  useGetAgroProductionDocumentSchemeQuery,
  useCreateSupplyProductionDocumentMutation,
  useSendToCorrectOperationalRequestMutation,
  useCreateCessionProductionDocumentMutation,
  useGetOperationalRequestDocumentSchemeQuery,
  useCreateOperationalRequestProductionDocumentMutation,
} = documentsApi;
