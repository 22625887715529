import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { controlError } from 'utils/controlError';

import s from './file.module.scss';

import { ErrorLabel } from 'components/error-label';
import { FieldLabel } from 'components/field-label';
import { Button } from 'components/ui/Button';
import { UploadImageField } from '../upload-image-field/select-filed';

export interface FileField {
  name: string;
  label?: string;
  disabled?: boolean;
}

export const FileField = ({ disabled, name, label }: FileField): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (field.value) {
      if (typeof field.value === 'string') {
        setFileName(field.value);
      } else if (field.value instanceof File) {
        setFileName(field.value.name);
      }
    } else {
      setFileName(null);
    }
  }, [field.value]);

  const error = t(controlError(meta, name) as string);

  const handleRemoveFile = () => {
    setFileName(null);
    helpers.setValue(null);
  };

  if (!fileName) {
    return <UploadImageField name={name} label={label} />;
  }

  return (
    <div>
      <FieldLabel label={label} />
      <div className={s['file-display']}>
        <span className={s['file-name']}>{fileName}</span>
        <Button
          text={t('Delete')}
          onClick={handleRemoveFile}
          className={cx(s['remove-button'], { [s['disabled-button']]: disabled })}
        />
      </div>

      <ErrorLabel error={error} />
    </div>
  );
};
