import { useTranslation } from 'react-i18next';

import { Loader } from 'components/loader/loader';
import { useGetShipmentRegistriesQuery } from 'store/api/documents';

import { NotFound, NotFoundContainer } from 'components/not-found';
import s from './All.module.scss';
import { ShipmentRegistryTable } from './ShipmentRegistryTable';

export const Label = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['label-container']}>
      <span className={s['label-text']}>{t(text)}</span>
    </div>
  );
};

const DEFAULT_COLUMNS = [
  'number',
  'period',
  'buyerInn',
  'buyerKpp',
  'buyer',
  'document',
  'agreement',
  'total',
  'statusId',
  // 'invoiceNumber',
  // 'invoiceDate',
  // 'invoiceTotal',
];

function All() {
  const { t } = useTranslation();

  const { data, isFetching } = useGetShipmentRegistriesQuery({});

  return (
    <>
      <span className={s['title']}>{t('Реестры отгрузок по договору цессии')}</span>
      <div className={s['container-content']}>
        <div className={s['main']}>
          {isFetching ? (
            <Loader size="big" />
          ) : (
            <>
              {data?.length === 0 ? (
                <NotFoundContainer>
                  <NotFound
                    notFoundTitle=""
                    hasSearch={false}
                    title="Реестры отгрузок"
                    subtitle="отсутствуют"
                  />
                </NotFoundContainer>
              ) : (
                <ShipmentRegistryTable data={data} selectedColumns={DEFAULT_COLUMNS} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
