import cx from 'classnames';
import { Formik } from 'formik';

import { SelectTagsField } from 'components/fields/select-fields-tags/select-fields-tags';
import { useTranslation } from 'react-i18next';

import { model, StatusOption, initialValues, validationSchema, FiltersValues } from './config';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import { useMemo, useState, useEffect } from 'react';
import s from './filter.module.scss';
import {
  useGetDocumentStatusesQuery,
  useGetOperationalRequetsFiltersQuery,
} from 'store/api/documents';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { DatePickerField } from 'components/common';

export const AsideFilter = ({
  isOpen,
  isMulti = true,
  status: initialStatus,
  onSubmitForm,
  onClose,
  onChangeStatus,
}: {
  isOpen: boolean;
  isMulti?: boolean;
  status: string[] | undefined;
  onSubmitForm: (values: FiltersValues) => void;
  onClose: () => void;
  onChangeStatus: (status: string[]) => void;
}) => {
  const { t } = useTranslation();

  const { data: statuses } = useGetDocumentStatusesQuery();
  const { data: filters = {} } = useGetOperationalRequetsFiltersQuery({});

  const [selectedStatus, setSelectedStatus] = useState<StatusOption[] | StatusOption>(
    (initialStatus ?? [])?.map((status) => ({ label: status, value: status })) || [],
  );

  const optionsStatuses = useMemo(() => {
    return statuses
      ?.filter((item) => !['initiated', 'purged', 'cancelled', 'pending'].includes(item.alias))
      ?.map((item) => ({ label: t(item?.alias), value: item?.id }));
  }, [statuses]);

  const optionsAgreements = useMemo(() => {
    return [{ key: 'all', valStr: 'all' }, ...(filters?.agreementId ?? [])]?.map(
      (item: { key: string; valStr: string }) => ({
        value: t(item?.key),
        label: item?.valStr,
      }),
    );
  }, [filters?.agreementId]);

  const currentStatuses = useMemo(() => {
    return statuses
      ?.filter((option) =>
        (Array.isArray(selectedStatus) ? selectedStatus : [selectedStatus]).some(
          (selected) => selected.value === option.id,
        ),
      )
      .map((item) => ({ label: t(item?.alias), value: item?.id }));
  }, [selectedStatus]);

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const handleStatusChange = (selectedOptions: any) => {
    const optionsArray = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions || []];
    setSelectedStatus(optionsArray);
  };

  const onSubmit = (values: FiltersValues) => {
    const { status } = values;

    let selectedStatus;
    if (Array.isArray(status)) {
      selectedStatus = status?.map((item) => item.value) || [];
    } else {
      selectedStatus = [(status as StatusOption).value];
    }
    onChangeStatus(selectedStatus);

    onSubmitForm?.(values);
    onClose?.();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, values, submitForm, setFieldValue }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Filter')}</span>

              <div role="button" tabIndex={0} className={s['close']} onClick={onClose}>
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['wrapper-select']}>
                <SelectTagsField
                  {...model.status}
                  isMulti={isMulti}
                  options={optionsStatuses}
                  value={currentStatuses}
                  onChange={handleStatusChange}
                />
              </div>

              <div className={s['wrapper-select']}>
                <SelectField {...model.agreement} isMulti={false} options={optionsAgreements} />
              </div>

              <div className={s['wrapper-select']}>
                <DatePickerField {...model.period} />
              </div>
            </div>

            <div className={s['footer']}>
              <Button className={classesButtonBack} onClick={onClose}>
                {t('Back')}
              </Button>
              <Button className={classesButtonSave} onClick={submitForm}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
