import { DatePickerMode } from 'components/common/date-picker/date-picker';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface StatusOption {
  label: string;
  value: string;
}

export interface FiltersValues {
  typeContract: string;
  status: StatusOption[];
  agreement: string;
  period: string;
  startDate: string;
  endDate: string;
}

export const model = {
  status: {
    label: 'Status',
    name: 'status',
    placeholder: 'Select status',
  },
  agreement: {
    label: 'Agreement',
    name: 'agreement',
    placeholder: 'Select agreement',
  },
  period: {
    type: 'date',
    mode: DatePickerMode.RANGE,
    name: 'period',
    label: 'Period',
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    //
  });

export const initialValues = {
  [model.status.name]: [],
  [model.agreement.name]: [],
  [model.agreement.name]: [],
  [model.period.name]: undefined,
} as unknown as FiltersValues;
