import { gql } from 'graphql-request';

export const GET_ALL_SHIPMENT_REPORTS = gql`
  query App5182dd71_Document {
    app5182dd71_ShipmentReport {
      getList(pagination: { page: 1, limit: 1000 }) {
        data {
          id
          number
          document
          agreement
          period
          quantity
          statusId
          orgSupplier
          orgBuyer
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
