import { SelectFieldProps } from 'components/fields/select-fields/select-filed';

import {
  SingleSelectValue,
  MultiSelectValue,
  SelectValue,
} from 'components/common/select/select-types';
import { AppDocument } from 'store/api/types';

export const isSingleSelectValue = (value: unknown): value is SingleSelectValue =>
  typeof value === 'object' && value !== null && 'value' in value && 'label' in value;

export const isMultiSelectValue = (value: unknown): value is MultiSelectValue => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return 'label' in value[0] && 'value' in value[0];
    }

    return true;
  }

  return false;
};

export const isSelectValue = (value: unknown): value is SelectValue =>
  isMultiSelectValue(value) || isSingleSelectValue(value);

export const getSelectValue = ({
  value,
  options,
  onlyValue,
}: Pick<SelectFieldProps, 'onlyValue' | 'options'> & {
  value: string | string[] | SelectValue;
}): SelectValue => {
  if (!onlyValue && isSelectValue(value)) return value;
  const getOption = (search?: any): SingleSelectValue => {
    let option = options?.find(
      (item) => !Array.isArray(item) && (item as any).value === search,
    );
    if (!option && search) {
      option = { label: search, value: search };
    }
    if (isSingleSelectValue(option)) return option;
  };
  if (Array.isArray(value)) {
    return value?.map((item) => getOption(item)) as MultiSelectValue;
  }
  return getOption(value) ?? null;
};

export const getDocumentContent = (document: AppDocument) => {
  return document?.tbd?.find((item) => item.type === 'base')?.content;
};

export const getDocumentSignData = (document?: AppDocument | null) => {
  return document?.tbs;
};
