import { selectIsLoggedIn } from 'modules/auth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';

export const useLimits = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn());

  const { data: myOrganizations, isFetching, refetch } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const isLimitedUser = !isFetching && !currentOrganization;

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn]);

  return { isLimitedUser, isFetching, refetch };
};
