import { gql } from 'graphql-request';

export const GET_ONE_OPERATIONAL_REQUEST_FILTERS = gql`
  query App5182dd71_OperationalRequest {
    app5182dd71_OperationalRequest {
      getFilterOptions {
        agreementId {
          key
          valStr
        }
      }
    }
  }
`;
