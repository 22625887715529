import { gql } from 'graphql-request';

export const GET_ONE_SHIPMENT_REPORT = gql`
  query App5182dd71_Document($documentId: String!) {
    app5182dd71_ShipmentReport {
      getOne(id: $documentId) {
        id
        number
        document
        agreement
        period
        quantity
        statusId
        orgSupplier
        orgBuyer
        data
      }
    }
  }
`;
