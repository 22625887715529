import cx from 'classnames';
import { PreviewRequestPageTemplate } from 'components/contracts/new-request/preview-request-page-template';
import { Icons } from 'components/icons';
import { Loader } from 'components/loader/loader';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetCoreDocumentsNoCacheMutation,
  useGetCoreDocumentsQuery,
  useGetDocumentStatusesQuery,
  useGetOneShipmentReportQuery,
  useSignDocumentMutation,
  useUpdateShipmentReportMutation,
} from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import { useHandleMutation } from 'utils/handleMutation';
import { ShipmentReportsTable } from '../all/ShipmentReportsTable';
import { AsideTableSettings } from '../all/aside-table-settings/table-settings'; // Import table settings component
import styles from './Details.module.scss';

const FIELDS = [
  'number',
  'agreement',
  'period',
  'quantity',
  'statusId',
  'orgSupplier',
  'orgBuyer',
];

const DEFAULT_COLUMNS = [
  'number',
  'product',
  'agreement',
  'requestDate',
  'deliveryBasis',
  'employeeOwner',
  'actualQuantity',
  'employeeReceiving',
  'actualShipmentDate',
];

export const Details = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();

  const [updateShipmentReport, updateShipmentReportMutation] =
    useUpdateShipmentReportMutation();

  const [signDocument, signDocumentMutation] = useSignDocumentMutation();

  const handleClose = useCallback(() => {
    navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentReports.Home);
  }, [navigate]);

  const onSuccessUpdate = useCallback(async () => {
    alert.success('Отчет по отгрузке успешно обновлен');
  }, [handleClose]);

  useHandleMutation({
    ...updateShipmentReportMutation,
    onSuccess: onSuccessUpdate,
  });

  const onSuccessSign = useCallback(async () => {
    handleClose();
  }, [handleClose]);

  useHandleMutation({
    ...signDocumentMutation,
    onSuccess: onSuccessSign,
  });

  const [isTableSettingsVisible, setTableSettingsVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(DEFAULT_COLUMNS);
  const excludeColumns = ['id', 'version', 'orgOwnerId', 'createdAt', 'updatedAt'];

  const { data: item = {}, isFetching } = useGetOneShipmentReportQuery(
    { documentId: id ?? '' },
    { skip: !id },
  );
  const { data: statuses } = useGetDocumentStatusesQuery();
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: [document] = [] } = useGetCoreDocumentsQuery(
    { ids: [item.id ?? ''] },
    { skip: !item.id },
  );

  const isOwner = (currentOrganization?.id ?? '') === item?.orgOwnerId;
  const rejectedStatusId = statuses?.find((item) => item?.alias === 'rejected')?.id;
  const approvedStatusId = statuses?.find((item) => item?.alias === 'approved')?.id;
  const signedStatusId = statuses?.find((item) => item?.alias === 'signed')?.id;

  // Column setup
  const columns = useMemo(
    () => Object.keys(item?.data?.[0] ?? {})?.map((item) => ({ key: item, label: item })),
    [item],
  );

  const handleColumnSelection = (values: any) => {
    const newSelectedColumns = Object.entries(values)
      .filter(([key, value]) => !!value)
      .sort((a, b) => DEFAULT_COLUMNS.indexOf(a[0]) - DEFAULT_COLUMNS.indexOf(b[0]))
      .map(([key]) => key);

    setSelectedColumns(newSelectedColumns);
    localStorage.setItem('shipmentreport.selectedColumns', JSON.stringify(newSelectedColumns));
  };

  const handleResetToDefaults = () => {
    setSelectedColumns(DEFAULT_COLUMNS);
    localStorage.setItem('shipmentreport.selectedColumns', JSON.stringify(DEFAULT_COLUMNS));
  };

  const [fetchDocument] = useGetCoreDocumentsNoCacheMutation({});

  const handleSign = useCallback(async () => {
    const { data } = (await fetchDocument({ ids: [id ?? ''] })) as any;

    const [lastDocument] = data ?? [];

    await signDocument({
      signature: null,
      detachedSignature: null,
      documentId: lastDocument?.id ?? '',
      documentVersion: lastDocument?.version,
    });
  }, [id]);

  useEffect(() => {
    const savedColumns = localStorage.getItem('shipmentreport.selectedColumns');
    if (savedColumns) {
      setSelectedColumns(JSON.parse(savedColumns));
    }
  }, []);

  if (isFetching) {
    return <Loader size="ultra" />;
  }

  const getValue = (key: string, value: any) => {
    if (typeof value === 'object') {
      return `${(value as any)?.[0]?.author ?? ''}\n ${(value as any)?.[0]?.message ?? ''}`;
    }

    if (key === 'statusId') {
      return t(statuses?.find((item) => item?.id === value)?.alias ?? '');
    }

    return value?.toString() ?? '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>Отчет по отгрузке</div>
        <div className={styles.card}>
          <PreviewRequestPageTemplate>
            {Object.entries(item ?? {})
              .filter(([key]) => FIELDS.includes(key))
              .sort((a, b) => FIELDS.indexOf(a[0]) - FIELDS.indexOf(b[0]))
              .map(([key, value]) => (
                <div key={key} className={styles.section}>
                  <div className={styles.label}>{t(`ShipmentReport.${key}`)}</div>
                  <div className={styles.value}>{getValue(key, value)}</div>
                </div>
              ))}
          </PreviewRequestPageTemplate>
        </div>

        <div
          style={{
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
            }}
          >
            <Button
              icon={<Icons.SettingsTable />}
              onClick={() => setTableSettingsVisible(true)}
              className={cx(styles['button'], styles.buttonSettings)}
              displayType="outlined-tetriary"
            />
          </div>

          <ShipmentReportsTable isDetails data={item.data} selectedColumns={selectedColumns} />
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            onClick={() =>
              navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentReports.Home)
            }
            type="tetriary"
            displayType="outlined-tetriary"
          >
            Отмена
          </Button>
          {item.statusId !== signedStatusId && item.statusId !== approvedStatusId && (
            <Button
              onClick={() => updateShipmentReport({ documentId: item.id })}
              displayType="outlined-primary"
              className={styles.refreshButton}
            >
              {updateShipmentReportMutation.isLoading ? t('Loading...') : 'Обновить'}
            </Button>
          )}

          {item.statusId !== signedStatusId && (
            <Button
              onClick={() => handleSign()}
              type="primary"
              className={styles.approveButton}
            >
              {signDocumentMutation.isLoading ? t('Loading...') : 'Согласовать'}
            </Button>
          )}
        </div>

        <AsideTableSettings
          labelTranslateModified="ShipmentReport."
          isOpen={isTableSettingsVisible}
          onClose={() => setTableSettingsVisible(false)}
          columns={columns.filter((item) => !excludeColumns.includes(item.key))}
          selectedColumns={selectedColumns}
          onSubmitForm={handleColumnSelection}
          onResetToDefaults={handleResetToDefaults}
        />
      </div>
    </div>
  );
};
