const Auth = {
  Home: '/authorization',
  EULA: '/eula',
  Email: '/email',
  PinCode: '/pin-code',
  ForgotPassword: '/forgot-password',
  StaffInvite: '/staff-invite',
  Certificate: '/certificate',
  Login: '/login',
  Offer: '/offer',
  Register: '/register',
  PoaCreate: '/poa-create',
  PoaAccept: '/poa-accept',
  PoaRequest: '/poa-request',
  PoaPreview: '/poa-preview',
  PoaSigned: '/poa-signed',
  UserRestricted: '/user-restricted',
  RegisterByRepresentative: '/register-by-representative',
};

const Profile = {
  Home: '/profile',
  PersonalData: '/personal-data',
  Authority: '/authority',
};

const OrganizationProfile = {
  Home: '/organization-profile',
};

const Settings = {
  Home: '/settings',
  EDOOperator: '/edo-operator',
  SettingsCommon: '/settings-common',
};

const Organizations = {
  Home: '/organization',
  Requisites: '/requisites',
  Documents: '/documents',
  Staff: '/staff',
  List: '/list',
  Details: '/details',
  Invites: '/invites',
  AddStaff: '/add-staff',
};

const Contracts = {
  Home: '/contracts',
  NewContract: '/new-contract',
  PreviewNewContract: '/preview',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  ContractApproval: '/contract-approval',
};

const Invitation = {
  Home: '/invitation',
  NDA: '/nda',
  Confirm: '/confirm',
  Agreement: '/agreement',
};

const CounterpatyDetail = {
  Home: '/all-counterparties',
  Documents: '/documents',
  Requisites: '/requisites',
};

const Counterparties = {
  Invitation,
  CounterpatyDetail,
  Home: '/counterparties',
  Invitations: '/invitations',
  Agreement: '/agreement',
};

const PowersAttorney = {
  Home: '/powers-attorney',
  Active: '/active',
  Revoked: '/revoked',
  Archived: '/archived',
  Drafts: '/drafts',
  NewAttorney: '/new-attorney',
  Preview: '/preview',
};

const OperationalRequests = {
  Home: '/operational-requests',
  NewRequest: '/new-request',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  RequestApproval: '/contract-approval',
};

const ShipmentTasks = {
  Home: '/shipment-tasks',
  NewRequest: '/new-request',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  RequestApproval: '/contract-approval',
};

const ControlCards = {
  Home: '/control-cards',
  NewRequest: '/new-request',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  RequestApproval: '/contract-approval',
};

const ShipmentReports = {
  Home: '/shipment-reports',
  NewRequest: '/new-request',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  RequestApproval: '/contract-approval',
};

const ShipmentRegistry = {
  Home: '/shipment-registries',
  NewRequest: '/new-request',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  Preview: '/preview',
  RequestApproval: '/contract-approval',
};

const Dashboard = {
  Profile,
  Contracts,
  Organizations,
  Counterparties,
  PowersAttorney,
  Settings,
  OrganizationProfile,
  ShipmentTasks,
  ShipmentReports,
  ControlCards,
  ShipmentRegistry,
  OperationalRequests,
  Home: '',
  Infopanel: '/infopanel',
};

export const AppRoutes = {
  Auth,
  Dashboard,
  NotFound: '/not-found',
};
