import { useTranslation } from 'react-i18next';

import { message } from 'antd';

import { useMutation } from '@tanstack/react-query';
import { finishRecovery, startRecovery } from 'api';
import { FinishRecoveryRequest, StartRecoveryRequest } from 'models';
import { useState } from 'react';

interface EmailAuthProps {
  email: string;
  password: string;
}

export default function useForgotPassword() {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: start } = useMutation((data: StartRecoveryRequest) =>
    startRecovery(data),
  );

  const { mutateAsync: finish } = useMutation((data: FinishRecoveryRequest) =>
    finishRecovery(data),
  );

  const requestResetEmail = async ({ email }: { email: string }) => {
    try {
      await start({ login: email });
      return true;
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      setLoading(false);
    }

    return false;
  };

  const resetPassword = async ({ password, token }: FinishRecoveryRequest) => {
    try {
      await finish({ password, token });
      return true;
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      setLoading(false);
    }

    return false;
  };

  return { requestResetEmail, resetPassword, isLoading };
}
