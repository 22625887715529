import { StatusDocument } from 'components/status/status-document';
import { useTranslation } from 'react-i18next';
import { OperationalRequest } from 'store/api/types/documents';
import s from './table.module.scss';

import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { useGetDocumentStatusesQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import Checkbox from 'antd/es/checkbox/Checkbox';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';

interface TaskItem extends OperationalRequest {
  taskId: string;
}

const getColumnStyle = (column: string) => {
  switch (column) {
    case 'number':
      return { maxWidth: 30, justifyContent: 'center' };
    case 'quantity':
      return { minWidth: 120, justifyContent: 'center' };
    case 'agreementName':
      return { minWidth: 300, maxHeight: 80, textOverflow: 'ellipsis' };
    case 'statusId':
      return { minWidth: 135 };
    default:
      return { minWidth: 100 };
  }
};

export const TableRow = ({
  selectedColumns,
  item,
  onSelectRow,
  selectedRows,
}: {
  onSelectRow: (value: string) => void;
  selectedRows: string[];
  item: TaskItem;
  selectedColumns: string[];
}) => {
  const navigate = useNavigate();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { taskId, statusId, orgOwnerId } = item;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgOwnerId;

  const { Home, ControlCards } = AppRoutes.Dashboard;

  const handleDetailContract = (id: string) => {
    if (statusId === statuses?.find((item) => item?.alias === 'rejected')?.id && isOwner) {
      navigate(Home + ControlCards.Home + ControlCards.NewRequest + `/${id}`);
      return;
    }
    if (statusId === statuses?.find((item) => item?.alias === 'approved')?.id && !isOwner) {
      navigate(Home + ControlCards.Home + ControlCards.RequestApproval + `/${id}`);
      return;
    }

    if (statusId === statuses?.find((item) => item?.alias === 'signed')?.id && !isOwner) {
      navigate(Home + ControlCards.Home + `/${id}`);
      return;
    }

    navigate(Home + ControlCards.Home + ControlCards.NewRequest + `/${id}`);
  };

  const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDetailContract(id);
    }
  };

  return (
    <div
      onKeyDown={(e) => handleKeyDown(taskId, e)}
      onClick={(e) => {
        e.stopPropagation();
        handleDetailContract(taskId);
      }}
      key={item.id}
      className={s['table-row']}
      role="button"
      tabIndex={0}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          style={{ height: 100, width: 24, paddingLeft: 8 }}
          checked={selectedRows.some((value) => value === item.taskId)}
          onChange={(e) => {
            e.stopPropagation();
            onSelectRow(item.taskId);
          }}
        />
      </div>

      {selectedColumns.map((column: string) => {
        return (
          <TableItem key={column} content={(item as any)[column]} data={item} column={column} />
        );
      })}
    </div>
  );
};

export const TableItem = ({
  data,
  content,
  column,
}: {
  data: OperationalRequest;
  content: JSX.Element;
  column: string;
}) => {
  const navigate = useNavigate();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { statusId, orgOwnerId } = data;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgOwnerId;

  if (column === 'statusId') {
    return (
      <div style={getColumnStyle(column)} className={s['table-column']}>
        <StatusDocument text={status?.alias} isOwner={isOwner} />
      </div>
    );
  }

  if (column?.toLocaleLowerCase()?.includes('date')) {
    return (
      <div className={s['table-column']}>
        <span className={s['column-text']}>
          {dayjs((content ?? '')?.toString()).format(DefaultDateFormat)}
        </span>
      </div>
    );
  }

  return (
    <div style={getColumnStyle(column)} className={s['table-column']}>
      <span className={s['column-text']}>{content}</span>
    </div>
  );
};

export const ControlCardsTable = ({
  data,
  selectedColumns,
  onSelectRow,
  selectedRows,
}: {
  selectedRows: string[];
  onSelectRow: (value: string) => void;
  selectedColumns: string[];
  data: TaskItem[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={s['table']}>
      <div className={s['table-header']}>
        {selectedColumns.map((item) => (
          <div style={getColumnStyle(item)} key={item} className={s['table-column']}>
            <span className={s['header-text']}>{t(item)}</span>
          </div>
        ))}
      </div>

      {data.map((item: TaskItem) => (
        <TableRow
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
          key={item.agreementName?.toString()}
          item={item}
          selectedColumns={selectedColumns}
        />
      ))}
    </div>
  );
};
