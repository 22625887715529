import { useTranslation } from 'react-i18next';
import styles from './NotFound.module.scss';

import { ReactComponent as ReportIcon } from 'assets/images/report.svg';
import React from 'react';

type Props = {
  title: string;
  hasSearch: boolean;
  notFoundTitle: string;
  subtitle?: string;
};

export const NotFound = ({ title, notFoundTitle, subtitle, hasSearch }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.notFound}>
      <ReportIcon />
      <span className={styles.textNotFound}>
        {hasSearch ? (
          <span>{t(notFoundTitle)}</span>
        ) : (
          <div>
            <span>{t(title)}</span>
            {subtitle && <div style={{ textAlign: 'center' }}>{t(subtitle)}</div>}
          </div>
        )}
      </span>
    </div>
  );
};

export const NotFoundContainer = ({ children }: React.PropsWithChildren) => (
  <div className={styles.notFoundContainer}>{children}</div>
);
