import { gql } from 'graphql-request';

export const UPDATE_SHIPMENT_REPORT_DOCUMENT = gql`
  mutation App5182dd71_Document($documentId: String!) {
    app5182dd71_ShipmentReport {
      update(documentId: $documentId) {
        status
        message {
          ru
          en
        }
      }
    }
  }
`;
