import { Route, Routes } from 'react-router-dom';

import All from './all/All';
import { Details } from './details/Details';

import { AppRoutes } from 'config/routes';

function ShipmentReports() {
  const { NewRequest, RequestApproval } = AppRoutes.Dashboard.ShipmentTasks;

  return (
    <Routes>
      <Route path="/" element={<All />} />
      <Route path="/:id/*" element={<Details />} />
    </Routes>
  );
}

export default ShipmentReports;
