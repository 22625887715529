import { PreviewRequestPageTemplate } from 'components/contracts/new-request/preview-request-page-template';
import { Loader } from 'components/loader/loader';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDocumentStatusesQuery, useGetOneControlCardQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import styles from './Details.module.scss';
import { DefaultDateFormat } from 'utils/date';
import dayjs from 'dayjs';

export const DetailRequest = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: data = {}, isFetching } = useGetOneControlCardQuery(
    { documentId: id ?? '' },
    { skip: !id },
  );

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const isOwner = (currentOrganization?.id ?? '') === data?.orgOwnerId;

  const rejectedStatusId = statuses?.find((item) => item?.alias === 'rejected')?.id;
  const approvedStatusId = statuses?.find((item) => item?.alias === 'approved')?.id;
  const signedStatusId = statuses?.find((item) => item?.alias === 'signed')?.id;

  if (isFetching) {
    return <Loader size="ultra" />;
  }

  const FIELDS = [
    'number',
    'requestDate',
    'agreementName',
    'shippingDate',
    'deliveryBasis',
    'goodsType',
    'quantity',
    'comment',
  ];

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>Контрольный талон</div>
        <div className={styles.contentBox}>
          <div className={styles.card}>
            <PreviewRequestPageTemplate>
              {Object.entries(data.shipmentTask?.operationalRequest ?? {})
                // Filter the fields to include only those in FIELDS
                .filter(([key]) => FIELDS.includes(key))
                // Sort by the order of fields in FIELDS array
                .sort((a, b) => FIELDS.indexOf(a[0]) - FIELDS.indexOf(b[0]))
                .map(([key, value]) => (
                  <div key={key} className={styles.section}>
                    <div className={styles.label}>{t(key)}</div>
                    <div className={styles.value}>
                      {(typeof value === 'object'
                        ? `${(value as any)?.[0]?.author ?? ''}\n ${
                            (value as any)?.[0]?.message ?? ''
                          }`
                        : key.toLocaleLowerCase()?.includes('date')
                        ? dayjs(value?.toString()).format(DefaultDateFormat)
                        : value?.toString()) ?? ''}
                    </div>
                  </div>
                ))}
            </PreviewRequestPageTemplate>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            onClick={() =>
              navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.OperationalRequests.Home)
            }
            type="tetriary"
            displayType="outlined-tetriary"
          >
            Отмена
          </Button>
          {!isOwner &&
            data.statusId !== rejectedStatusId &&
            data.statusId !== signedStatusId &&
            data.statusId !== approvedStatusId && (
              <>
                <Button
                  type="primary"
                  displayType="outlined-primary"
                  className={styles.editButton}
                >
                  Вернуть на корректировку
                </Button>
                <Button type="primary" className={styles.approveButton}>
                  Согласовать
                </Button>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
