import { useClient } from 'client/hooks/useClient';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { setMode } from '../../../store/reducers/auth.reducer';
import styles from './CheckClientModal.module.scss';

interface CustomModalProps {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ title, isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose} aria-label="Close modal">
          X
        </button>
        <h2 className={styles.modalTitle}>{title}</h2>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export const useHeartbeat = () => {
  const [isAvailable, setAvailable] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { checkMockClient, checkJCWebClient, checkVPIConnection, checkCryptoProConnection } =
    useClient();

  useEffect(() => {
    const sendHeartbeat = async () => {
      let isMockAvailable = true;
      let isJCWebClientAvailable = true;
      let isVipAvailable = true;
      let isCryptoProAvailable = true;

      setLoading(true);

      try {
        isMockAvailable = await checkMockClient();
      } catch (error) {
        isMockAvailable = false;
        console.error(error);
      }

      try {
        isJCWebClientAvailable = checkJCWebClient();
      } catch (error) {
        isJCWebClientAvailable = false;
        console.warn(error);
      }

      try {
        isVipAvailable = await checkVPIConnection();
      } catch (error) {
        isVipAvailable = false;
        console.error(error);
      }

      try {
        isCryptoProAvailable = await checkCryptoProConnection();
      } catch (error) {
        console.error(error);
      }

      if (
        !isVipAvailable &&
        !isCryptoProAvailable &&
        !isJCWebClientAvailable &&
        !isMockAvailable
      ) {
        setAvailable(false);
      }

      if (isVipAvailable || isCryptoProAvailable || isJCWebClientAvailable || isMockAvailable) {
        setAvailable(true);
      }
      setLoading(true);
    };

    const interval = setInterval(sendHeartbeat, 5000);

    sendHeartbeat();

    return () => clearInterval(interval);
  }, []);

  return { isAvailable, isLoading };
};

export const CheckClientModal = ({ isCertificate }: { isCertificate?: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isAvailable, isLoading: isConectivityLoading } = useHeartbeat();

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(!isAvailable);
  }, []);

  useEffect(() => {
    if (isCertificate) {
      setModalOpen(!isAvailable);
      dispatch(setMode(isConectivityLoading ? 'prepearing' : 'default'));
    }
  }, [dispatch, isAvailable, isCertificate]);

  // Close handler
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <CustomModal
      title={t('Install ViPNet PKI Client')}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
    >
      <p>
        {t(
          'For the site to work, it is necessary that the ViPNet PKI Client with the Web Unit module is installed on your computer.',
        )}
      </p>
      <p>
        {t('Product information is available at the link')}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://infotecs.ru/product/vipnet-pki-client.html#soft"
          className={styles.link}
        >
          {t('link')}.
        </a>
      </p>
    </CustomModal>
  );
};
