import { gql } from 'graphql-request';

// query App5182dd71_Document($status: String, $searchQuery: String) {
// status_id: $status
// agreement: $searchQuery

export const GET_ALL_OPERATIONAL_DOCUMETNS = gql`
  query App5182dd71_OperationalRequest(
    $statusIds: [String!]
    $input: String
    $agreementId: String
    $shippingDateBefore: String
    $shippingDateAfter: String
  ) {
    app5182dd71_OperationalRequest {
      getList(
        filters: {
          input: $input
          statusIds: $statusIds
          agreementId: $agreementId
          shippingDateBefore: $shippingDateBefore
          shippingDateAfter: $shippingDateAfter
        }
        pagination: { limit: 1000 }
      ) {
        data {
          id
          version
          statusId
          orgOwnerId
          employeeOwner
          employeeReceiving
          agreementName
          number
          requestDate
          shippingDate
          deliveryBasis
          supplyAddress
          goodsType
          quantity
          createdAt
          updatedAt
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
