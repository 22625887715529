import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { InviteStatus } from 'store/api/types/organizations-invitations';
import s from './status.module.scss';

export const StatusDocument = ({
  text,
  isOwner,
}: {
  isOwner?: boolean;
  variant?: string;
  text?: InviteStatus | string;
}) => {
  const { t } = useTranslation();

  let statusText = '';

  switch (text) {
    case 'draft':
      statusText = t('Черновик');
      break;
    case 'sent':
      statusText = t('Отправлен');
      break;
    case 'approved':
      statusText = t(isOwner ? 'Направлен на подписание' : 'На подписании');
      break;
    case 'signed':
      statusText = t('Подписан');
      break;
    case 'terminated':
      statusText = t('Расторгнут');
      break;
    case 'archived':
      statusText = t('Архивиров');
      break;
    case 'done':
      statusText = t('Завершен');
      break;
    case 'rejected':
      statusText = t('Не согласовано');
      break;
    default:
      statusText = 'Неизвестен';
  }

  const statusClasses = cx(s['root'], {
    [s['active']]: text === 'signed',
    [s['blocked']]: text === 'rejected',
    [s['secondary-expectation']]: text === 'approved' && !isOwner,
    [s['expectation']]: text === 'sent' || (text === 'approved' && isOwner),
    [s['draft']]:
      text === 'draft' || text === 'terminated' || text === 'archived' || text === 'done',
  });

  return <span className={statusClasses}>{t(statusText ?? '')}</span>;
};
