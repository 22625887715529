import React, { ReactNode } from 'react';

interface PageTemplateProps {
  children: ReactNode[];
}

const HorizontalDivider = () => (
  <div
    style={{
      opacity: 0.2,
      height: 1,
      width: '100%',
      background: '#4F6281',
    }}
  />
);

const VerticalDivider = () => (
  <div style={{ opacity: 0.2, height: 70, width: 1, background: '#4F6281' }} />
);

export const PreviewRequestPageTemplate: React.FC<PageTemplateProps> = ({ children }) => {
  return (
    <div
      style={{
        padding: '2em',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', gap: 48 }}>
        <div style={{ color: '#4F6281', fontSize: 14, paddingRight: 126, fontWeight: 500 }}>
          {children[0]}
        </div>

        <div style={{ height: 60, overflow: 'hidden', marginBottom: 12 }}>
          <VerticalDivider />
        </div>

        <div style={{ color: '#050E1E', fontSize: 16, paddingLeft: 24, fontWeight: 500 }}>
          {children[1]}
        </div>
      </div>

      <HorizontalDivider />

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div style={{ color: '#050E1E', fontSize: 16, fontWeight: 500 }}>{children[2]}</div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div style={{ color: '#050E1E', fontSize: 16, fontWeight: 500 }}>{children[3]}</div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div style={{ color: '#050E1E', fontSize: 16, fontWeight: 500 }}>{children[4]}</div>
        </div>
        <HorizontalDivider />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div style={{ color: '#050E1E', fontSize: 16, fontWeight: 500 }}>{children[5]}</div>
        </div>
        <HorizontalDivider />
      </div>

      {children.slice(6).map((item) => (
        <div key={item?.toString()} style={{ display: 'flex', gap: 48, marginTop: 24 }}>
          <div style={{ color: '#4F6281', fontSize: 14, paddingRight: 24, fontWeight: 500 }}>
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};
