import logo from 'dynamic/assets/logo-dashboard.svg';
import logoAuth from 'dynamic/assets/logo-login.svg';
import styles from './ContracteumLogo.module.scss';
import { useTranslation } from 'react-i18next';

export const ContracteumLogo = ({ variant = 'dashboard' }: { variant?: string }) => {
  const { t } = useTranslation();

  if (variant === 'dashboard') {
    return (
      <div className={styles.container}>
        <img src={logo} alt="Contracteum" />
        <span className={styles.textLogoDashboard}>{t('AppName')}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img src={logoAuth} alt="Contracteum" />
      <span className={styles.textLogoAuth}>{t('AppName')}</span>
    </div>
  );
};
