import { AppRoutes } from 'config/routes';
import { handleAuth, selectIsLoggedIn } from 'modules/auth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { selectCurrentCertificate } from 'store/reducers/certificates';
import {
  selectInviteId,
  setInviteId,
  setStaffInviteId,
} from 'store/reducers/invite.conteragent';
import { isCertificatePerson } from 'utils/certificate';

export const useInvitation = () => {
  const inviteId = new URLSearchParams(window.location?.search || '').get('inviteId');
  const staffInviteId = new URLSearchParams(window.location?.search || '').get('staffInviteId');
  const poaId = new URLSearchParams(window.location?.search || '').get('poaId');
  const access_token = new URLSearchParams(window.location?.search || '').get('access_token');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn());
  const savedInviteId = useSelector(selectInviteId());

  const { Home, Counterparties: CounterpartiesRoute } = AppRoutes.Dashboard;
  const { Auth } = AppRoutes;

  const handleRedirect = () => {
    const isAttorneyRequired = isCertificatePerson();
    if (isLoggedIn && savedInviteId?.length && isAttorneyRequired) {
      navigate(`${Auth.Home}${Auth.PoaCreate}`);
      return;
    }

    const redirectTo = localStorage?.getItem('redirectTo');
    if (isLoggedIn && redirectTo) {
      navigate(redirectTo);
      localStorage.setItem('redirectTo', '');
      return;
    }

    if (!poaId && isLoggedIn && (savedInviteId?.length || inviteId?.length)) {
      navigate(
        Home +
          CounterpartiesRoute.Home +
          CounterpartiesRoute.Invitations +
          `/${inviteId ?? savedInviteId}`,
      );
      dispatch(setInviteId(null));
    }

    if (!isLoggedIn && inviteId) {
      dispatch(setInviteId(inviteId));
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [inviteId, isLoggedIn, savedInviteId]);

  useEffect(() => {
    if (staffInviteId) {
      dispatch(setStaffInviteId(staffInviteId));
      navigate(Auth.Home + Auth.StaffInvite + `/?token=${staffInviteId}`);
    }
  }, []);

  useEffect(() => {
    if (poaId && access_token) {
      dispatch(handleAuth({ access_token }));
      navigate(`${Auth.Home}${Auth.PoaAccept}/${poaId}`);
    }
  }, [poaId, access_token]);
};
