import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { Form, Formik } from 'formik';
import useEmailRegister from 'hooks/use-email-register';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from './PinCodeForm.module.scss';

export const PinCodeForm = () => {
  const { t } = useTranslation();
  const [pinCode, setPinCode] = useState<string[]>(new Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const token = new URLSearchParams(window.location?.search || '').get('token');
  const password = new URLSearchParams(window.location?.search || '').get('password');

  const { register, isLoading } = useEmailRegister();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const value = e.target.value;

    if (!/^\d$/.test(value) && value !== '') return;

    const updatedPinCode = [...pinCode];
    updatedPinCode[idx] = value;
    setPinCode(updatedPinCode);

    if (value !== '' && idx < 5) {
      inputRefs.current[idx + 1]?.focus();
    }

    if (idx === 5 && value !== '') {
      onSubmit(updatedPinCode.join(''));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    if (e.key === 'Backspace' && pinCode[idx] === '') {
      if (idx > 0) {
        inputRefs.current[idx - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedValue)) {
      const updatedPinCode = pastedValue.split('');
      setPinCode(updatedPinCode);
      updatedPinCode.forEach((digit, idx) => {
        if (inputRefs.current[idx]) {
          inputRefs.current[idx]!.value = digit;
        }
      });
      onSubmit(pastedValue);
    }
  };

  const onSubmit = async (pinCode: string) => {
    if (password?.length && token?.length && pinCode?.toString().length) {
      await register({ password, pinCode: pinCode.toString(), token });
    }
  };

  return (
    <Formik initialValues={{ pinCode: '' }} onSubmit={() => onSubmit(pinCode.join(''))}>
      {({ isSubmitting }) => (
        <Form className={s.form}>
          <div className={s.textContainer}>
            <h1 className={s.title}>{t('Email Verification')}</h1>
            <p className={s.subtitle}>
              {t(
                'We have sent a 6-digit verification code to your email. Please enter the code below to verify your account.',
              )}
            </p>
          </div>

          <div className={s.pinCodeContainer}>
            {pinCode.map((digit, idx) => (
              <input
                key={idx}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleInputChange(e, idx)}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                onPaste={handlePaste}
                ref={(ref) => (inputRefs.current[idx] = ref)}
                className={s.pinInput}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={idx === 0}
              />
            ))}
          </div>

          <div className={s.buttonContainer}>
            <Button type="primary" disabled={isSubmitting}>
              {t(isLoading ? 'Loading...' : 'Submit')}
            </Button>
          </div>

          <div className={s.forgotLink}>
            <a
              style={{ color: 'black' }}
              href={AppRoutes.Auth.Home + AppRoutes.Auth.Login + AppRoutes.Auth.Email}
            >
              {t('Back')}
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};
