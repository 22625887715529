import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectInvitationsType } from 'store/reducers/invite.list';

import { UserCard } from 'components/card';
import { RejectModal } from 'components/modal';
import { Button } from 'components/ui/Button';

import { NdaContractPreviewAcceptInvite } from 'components/contracts/nda-preview-accept-invite/NdaContractPreviewAcceptInvite';
import { toBase64 } from 'helpers';
import useSigner from 'client/hooks/use-signer';
import { useAlert } from 'react-alert';
import { useGetCoreDocumentsQuery } from 'store/api/documents';
import { useConfirmInvitationMutation } from 'store/api/organizations-invitations';
import { PartyInfo } from 'store/api/types/organizations-invitations';
import { useHandleMutation } from 'utils/handleMutation';
import s from './InviteInfo.module.scss';
import { getDocumentContent, getDocumentSignData } from 'utils/helpers';
import { AppDocument } from 'store/api/types';
import { AppRoutes } from 'config/routes';

const VarinatMessage = {
  Incoming: 'incoming',
  Outgoing: 'outgoing',
};

const OutgoingMessageButtons = ({
  inviteId,
  documentContent,
}: {
  documentContent?: string | null;
  inviteId: string;
}) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const onCopy = async () => {
    try {
      const link = `${window.location.origin}?inviteId=${inviteId}`;
      await navigator.clipboard.writeText(link ?? '');
      alert.success(t('Link Copied'));
    } catch (err) {
      alert.error(t('Error!'));
    }
  };

  const onViewNDA = async () => {
    alert.success('View NDA!');
  };

  return (
    <>
      {false && documentContent && (
        <Button onClick={onViewNDA} className={cx(s['button'], s['button-back'])}>
          {t('View NDA')}
        </Button>
      )}

      {
        <Button className={cx(s['button'], s['button-primary'])} onClick={onCopy}>
          {t('Copy Link')}
        </Button>
      }
    </>
  );
};

const IncomingMessageButtons = ({
  inviteId,
  isSignRequired,
  document,
  setModalVisible,
}: {
  inviteId: string;
  document?: AppDocument | null;
  isSignRequired?: boolean | null;
  setModalVisible: (value: boolean) => void;
}) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [confirmInvite, ConfirmInviteMutation] = useConfirmInvitationMutation();

  const onSuccessConfirm = useCallback(() => {
    alert.success(t('Invite accepted'));
  }, [alert, t]);

  const onErrorConfirm = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  useHandleMutation({
    ...ConfirmInviteMutation,
    onError: onErrorConfirm,
    onSuccess: onSuccessConfirm,
  });

  const { handleSign, isLoading: isSignerLoading } = useSigner();

  const handleSignDocument = async () => {
    const signData = getDocumentSignData(document);

    if (signData) {
      await handleSign({
        addTemplate: true,
        signData: signData,
        onSubmit: async ({ signature, detachedSignature }) => {
          await confirmInvite({
            inviteId: inviteId ?? '',
            partnershipAgreement: true,
            signature,
            detachedSignature,
          });
        },
      });
    }
  };

  const acceptInvitation = async () => {
    const signData = getDocumentSignData(document);

    if (signData) {
      await handleSignDocument();
      return;
    }
    await confirmInvite({ partnershipAgreement: true, inviteId });
  };

  return (
    <>
      <Button
        className={cx(s['button'], s['button-back'])}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {t('Reject')}
      </Button>

      <Button
        disabled={isSignerLoading}
        onClick={acceptInvitation}
        className={cx(s['button'], s['button-primary'])}
      >
        {t(isSignRequired ? 'Accept the invitation and sign the NDA' : 'Accept the invitation')}
      </Button>
    </>
  );
};

export const InviteInfo = ({ currentInvite }: { currentInvite: PartyInfo | undefined }) => {
  const type = useSelector(selectInvitationsType);
  const [isModalVisible, setModalVisible] = useState(false);

  const { Home, OrganizationProfile } = AppRoutes.Dashboard;

  const { data: [document] = [], isFetching: isFetchingDocument } = useGetCoreDocumentsQuery(
    { ids: [currentInvite?.joinDocumentId ?? ''] },
    { skip: !currentInvite?.joinDocumentId },
  );

  const isSignRequired = !!currentInvite?.joinDocumentId;
  const documentContent = document?.content
    ? toBase64(getDocumentContent(document) ?? '')
    : null;

  if (!currentInvite) {
    return null;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper']}>
        <UserCard
          redirectTo={Home + OrganizationProfile.Home + `/${currentInvite?.partyOrganization?.id}`}
          item={currentInvite}
        />
        {(currentInvite?.joinDocumentId || currentInvite?.status === 'approved') && (
          <NdaContractPreviewAcceptInvite
            isLoading={isFetchingDocument}
            documentContent={documentContent}
          />
        )}
        {currentInvite.status !== 'approved' && (
          <div className={s['footer']}>
            {type === VarinatMessage.Outgoing && (
              <OutgoingMessageButtons
                inviteId={currentInvite.id}
                documentContent={documentContent}
              />
            )}
            {type === VarinatMessage.Incoming && (
              <IncomingMessageButtons
                inviteId={currentInvite.id}
                isSignRequired={isSignRequired}
                document={document}
                setModalVisible={setModalVisible}
              />
            )}
          </div>
        )}
      </div>

      <RejectModal
        item={currentInvite}
        inviteId={currentInvite.id}
        isVisible={isModalVisible}
        setVisible={setModalVisible}
      />
    </div>
  );
};
